import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
	declarations: [],
	imports: [TableModule, TabViewModule, AutoCompleteModule, CalendarModule, InputNumberModule],
	exports: [TableModule, TabViewModule, AutoCompleteModule, CalendarModule, InputNumberModule]
})
export class PrimengModule {}
