import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'src/app/shared/data-access/pipes/pipe.module';
import { ButtonModule } from '../../input/button/button.module';
import { PrimengModule } from '../../primeng/primeng.module';
import { TableComponent } from './table.component';

@NgModule({
	declarations: [TableComponent],
	imports: [CommonModule, RouterModule, TranslateModule, PrimengModule, ButtonModule, PipeModule],
	exports: [TableComponent],
	providers: [],
	bootstrap: []
})
export class TableModule {}
