import { Injectable } from '@angular/core';
import { ServerAuthenticationService } from 'src/app/pages/authentication/data-access/services/server-authentication.service';
import { LocalStorageKeys } from '../../models/local-storage-keys';
import {
	CsvSeparator,
	GetUserInformationByAppUserIdResponse,
	NumberFormatType,
	TranslationType
} from '../../models/server-requests-responses.model';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserInformationService {
	private userInformation: GetUserInformationByAppUserIdResponse | undefined;

	constructor(
		private serverAuthenticationService: ServerAuthenticationService,
		private localStorageService: LocalStorageService
	) {}

	public initialize(): Promise<boolean> {
		return new Promise((resolve) => {
			const authenticated = this.localStorageService.getItem(LocalStorageKeys.AUTHENTICATED);
			if (!authenticated) {
				resolve(false);
				return;
			}

			this.serverAuthenticationService
				.getUserInformationByAppUserId()
				.pipe()
				.subscribe({
					next: (response) => {
						this.userInformation = response;
						resolve(true);
					},
					error: () => {
						this.localStorageService.clear();
						resolve(false);
					}
				});
		});
	}

	public removeUserInformation(): void {
		this.userInformation = undefined;
	}

	public getPermissions(): string[] | undefined {
		const authenticated = this.isUserAuthenticated();
		if (!authenticated) {
			return undefined;
		}

		return this.userInformation?.permissions;
	}

	public getTenantTitle(): string {
		const authenticated = this.isUserAuthenticated();
		const tenantTitle = this.userInformation?.tenantTitle;

		if (!authenticated || tenantTitle === undefined) {
			return '';
		}

		return tenantTitle;
	}

	public getAppUserId(): string {
		const isAuthenticated = this.isUserAuthenticated();
		const appUserId = this.userInformation?.appUserId;

		if (!isAuthenticated || appUserId === undefined) {
			return '';
		}

		return appUserId;
	}

	public getNumberFormat(): NumberFormatType {
		const isAuthenticated = this.isUserAuthenticated();
		const numberFormat = this.userInformation?.numberFormat;

		if (!isAuthenticated || numberFormat === undefined) {
			return NumberFormatType.Danish;
		}

		return numberFormat;
	}

	public getFullName(): string {
		const isAuthenticated = this.isUserAuthenticated();
		const firstName = this.userInformation?.firstName;
		const surname = this.userInformation?.surname;

		if (!isAuthenticated || firstName === undefined || surname === undefined) {
			return '';
		}

		return firstName + ' ' + surname;
	}

	public getUserInitials(): string {
		const isAuthenticated = this.isUserAuthenticated();
		const initials = this.userInformation?.initials;

		if (!isAuthenticated || initials === undefined) {
			return '';
		}

		return initials;
	}

	public getRoleTitle(): string {
		const isAuthenticated = this.isUserAuthenticated();
		const roleTitle = this.userInformation?.roleTitle;

		if (!isAuthenticated || roleTitle === undefined) {
			return '';
		}

		return roleTitle;
	}

	public getTranslation(): TranslationType | undefined {
		const isAuthenticated = this.isUserAuthenticated();
		const translation = this.userInformation?.translation;

		if (!isAuthenticated || translation === undefined) {
			return undefined;
		}

		return translation;
	}

	public getCsvSeparator(): CsvSeparator | undefined {
		const isAuthenticated = this.isUserAuthenticated();
		const separator = this.userInformation?.csvSeparator;

		if (!isAuthenticated || separator === undefined) {
			return undefined;
		}

		return separator;
	}

	public usesDanishTranslation(): boolean {
		const isAuthenticated = this.isUserAuthenticated();
		const translation = this.userInformation?.translation;

		if (!isAuthenticated || translation === undefined) {
			return false;
		}

		if (translation === TranslationType.Da_DK) {
			return true;
		}

		return false;
	}

	public usesEnglishTranslation(): boolean {
		const isAuthenticated = this.isUserAuthenticated();
		const translation = this.userInformation?.translation;

		if (!isAuthenticated || translation === undefined) {
			return false;
		}

		if (translation === TranslationType.En_UK) {
			return true;
		}

		return false;
	}

	public usesGermanTranslation(): boolean {
		const isAuthenticated = this.isUserAuthenticated();
		const translation = this.userInformation?.translation;

		if (!isAuthenticated || translation === undefined) {
			return false;
		}

		if (translation === TranslationType.De_DE) {
			return true;
		}

		return false;
	}

	private isUserAuthenticated(): boolean {
		const authenticated = this.localStorageService.getItem(LocalStorageKeys.AUTHENTICATED);

		if (authenticated === null || authenticated === undefined || authenticated === false) {
			return false;
		}

		return true;
	}
}
