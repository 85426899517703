import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionItemComponent } from './accordion-item.component';

@NgModule({
	declarations: [AccordionItemComponent],
	imports: [CommonModule, RouterModule, TranslateModule],
	exports: [AccordionItemComponent],
	providers: [],
	bootstrap: []
})
export class AccordionItemModule {}
