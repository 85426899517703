import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	CreateDocumentDatasheetResponse,
	CreateDocumentFillerMaterialCertificateResponse,
	CreateDocumentImageResponse,
	CreateDocumentMaterialCertificateResponse,
	CreateDocumentTemplateResponse,
	CreateDocumentWelderCertificateResponse,
	CreateDocumentWeldingProcedureResponse,
	GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse,
	GetAllDocumentDatasheetsByTenantIdResponse,
	GetAllDocumentFillerMaterialCertificatesByTenantIdResponse,
	GetAllDocumentImagesByTenantIdResponse,
	GetAllDocumentMaterialCertificatesByTenantIdResponse,
	GetAllDocumentTemplatesByTenantIdResponse,
	GetAllDocumentWelderCertificatesByEmployeeIdResponse,
	GetAllDocumentWelderCertificatesByTenantIdResponse,
	GetAllDocumentWeldingProceduresByTenantIdResponse,
	GetAllDocumentsByTenantIdResponse,
	GetDocumentDatasheetByIdResponse,
	GetDocumentFillerMaterialCertificateByIdResponse,
	GetDocumentImageByIdResponse,
	GetDocumentMaterialCertificateByIdResponse,
	GetDocumentTemplateByIdResponse,
	GetDocumentWelderCertificateByIdResponse,
	GetDocumentWeldingProcedureByIdResponse,
	UpdateDocumentDatasheetCommand,
	UpdateDocumentFillerMaterialCertificateCommand,
	UpdateDocumentImageCommand,
	UpdateDocumentMaterialCertificateCommand,
	UpdateDocumentTemplateCommand,
	UpdateDocumentWelderCertificateCommand,
	UpdateDocumentWeldingProcedureCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class DocumentService extends RestService {
	constructor(httpClient: HttpClient, restInterceptor: RestInterceptor) {
		super(httpClient, restInterceptor, '/document');
	}

	public getDocumentFillerMaterialCertificateById(
		id: string
	): Observable<GetDocumentFillerMaterialCertificateByIdResponse> {
		return super.get<GetDocumentFillerMaterialCertificateByIdResponse>(`/filler-material-certificate/${id}`);
	}

	public getDocumentMaterialCertificateById(id: string): Observable<GetDocumentMaterialCertificateByIdResponse> {
		return super.get<GetDocumentMaterialCertificateByIdResponse>(`/material-certificate/${id}`);
	}

	public getDocumentDatasheetById(id: string): Observable<GetDocumentDatasheetByIdResponse> {
		return super.get<GetDocumentDatasheetByIdResponse>(`/datasheet/${id}`);
	}

	public getDocumentWelderCertificateById(id: string): Observable<GetDocumentWelderCertificateByIdResponse> {
		return super.get<GetDocumentWelderCertificateByIdResponse>(`/welder-certificate/${id}`);
	}

	public getDocumentWeldingProcedureById(id: string): Observable<GetDocumentWeldingProcedureByIdResponse> {
		return super.get<GetDocumentWeldingProcedureByIdResponse>(`/welding-procedure/${id}`);
	}

	public getDocumentTemplateById(id: string): Observable<GetDocumentTemplateByIdResponse> {
		return super.get<GetDocumentTemplateByIdResponse>(`/template/${id}`);
	}

	public getDocumentImageById(id: string): Observable<GetDocumentImageByIdResponse> {
		return super.get<GetDocumentImageByIdResponse>(`/image/${id}`);
	}

	public getAllDocumentsByTenantId(): Observable<GetAllDocumentsByTenantIdResponse[]> {
		return super.get<GetAllDocumentsByTenantIdResponse[]>(`/documents/tenant-id`);
	}

	public getAllDocumentFillerMaterialCertificatesByTenantId(): Observable<
		GetAllDocumentFillerMaterialCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentFillerMaterialCertificatesByTenantIdResponse[]>(
			`/filler-material-certificates/tenant-id`
		);
	}

	public getAllDocumentMaterialCertificatesByTenantId(): Observable<
		GetAllDocumentMaterialCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentMaterialCertificatesByTenantIdResponse[]>(`/material-certificates/tenant-id`);
	}

	public getAllDocumentDatasheetsByTenantId(): Observable<GetAllDocumentDatasheetsByTenantIdResponse[]> {
		return super.get<GetAllDocumentDatasheetsByTenantIdResponse[]>(`/datasheets/tenant-id`);
	}

	public getAllDocumentWelderCertificatesByTenantId(): Observable<
		GetAllDocumentWelderCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentWelderCertificatesByTenantIdResponse[]>(`/welder-certificates/tenant-id`);
	}

	public getAllDocumentWeldingProceduresByTenantId(): Observable<GetAllDocumentWeldingProceduresByTenantIdResponse[]> {
		return super.get<GetAllDocumentWeldingProceduresByTenantIdResponse[]>(`/welding-procedures/tenant-id`);
	}

	public getAllDocumentTemplatesByTenantId(): Observable<GetAllDocumentTemplatesByTenantIdResponse[]> {
		return super.get<GetAllDocumentTemplatesByTenantIdResponse[]>(`/templates/tenant-id`);
	}

	public getAllDocumentImagesByTenantId(): Observable<GetAllDocumentImagesByTenantIdResponse[]> {
		return super.get<GetAllDocumentImagesByTenantIdResponse[]>(`/images/tenant-id`);
	}

	public getAllDocumentWelderCertificatesByEmployeeId(
		employeeId: string
	): Observable<GetAllDocumentWelderCertificatesByEmployeeIdResponse[]> {
		return super.get<GetAllDocumentWelderCertificatesByEmployeeIdResponse[]>(
			`/welder-certificates/employee-id/${employeeId}`
		);
	}

	public getAllAboutToExpireDocumentWelderCertificatesByTenantId(): Observable<
		GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse[]>(
			`/welder-certificates/about-to-expire/tenant-id`
		);
	}

	public downloadDocument(documentId: string): Observable<Blob> {
		return super.get<Blob>(`/download/${documentId}`, 'blob');
	}

	public createDocumentMaterialCertificate(command: FormData): Observable<CreateDocumentMaterialCertificateResponse> {
		return super.post<CreateDocumentMaterialCertificateResponse>('/material-certificate', command);
	}

	public createDocumentTemplate(command: FormData): Observable<CreateDocumentTemplateResponse> {
		return super.post<CreateDocumentTemplateResponse>('/template', command);
	}

	public createDocumentImage(command: FormData): Observable<CreateDocumentImageResponse> {
		return super.post<CreateDocumentImageResponse>('/image', command);
	}

	public createDocumentDatasheet(command: FormData): Observable<CreateDocumentDatasheetResponse> {
		return super.post<CreateDocumentDatasheetResponse>('/datasheet', command);
	}

	public createDocumentFillerMaterialCertificate(
		command: FormData
	): Observable<CreateDocumentFillerMaterialCertificateResponse> {
		return super.post<CreateDocumentFillerMaterialCertificateResponse>('/filler-material-certificate', command);
	}

	public createDocumentWelderCertificate(command: FormData): Observable<CreateDocumentWelderCertificateResponse> {
		return super.post<CreateDocumentWelderCertificateResponse>('/welder-certificate', command);
	}

	public createDocumentWeldingProcedure(command: FormData): Observable<CreateDocumentWeldingProcedureResponse> {
		return super.post<CreateDocumentWeldingProcedureResponse>('/welding-procedure', command);
	}

	public updateDocumentData(command: FormData): Observable<void> {
		return super.patch<void>('', command);
	}

	public updateDocumentMaterialCertificate(command: UpdateDocumentMaterialCertificateCommand): Observable<void> {
		return super.patch<void>('/material-certificate', command);
	}

	public updateDocumentTemplate(command: UpdateDocumentTemplateCommand): Observable<void> {
		return super.patch<void>('/template', command);
	}

	public updateDocumentImage(command: UpdateDocumentImageCommand): Observable<void> {
		return super.patch<void>('/image', command);
	}

	public updateDocumentDatasheet(command: UpdateDocumentDatasheetCommand): Observable<void> {
		return super.patch<void>('/datasheet', command);
	}

	public updateDocumentFillerMaterialCertificate(
		command: UpdateDocumentFillerMaterialCertificateCommand
	): Observable<void> {
		return super.patch<void>('/filler-material-certificate', command);
	}

	public updateDocumentWelderCertificate(command: UpdateDocumentWelderCertificateCommand): Observable<void> {
		return super.patch<void>('/welder-certificate', command);
	}

	public updateDocumentWeldingProcedure(command: UpdateDocumentWeldingProcedureCommand): Observable<void> {
		return super.patch<void>('/welding-procedure', command);
	}

	public deleteDocumentTemplate(id: string): Observable<void> {
		return super.delete<void>(`/template/${id}`);
	}

	public deleteDocumentImage(id: string): Observable<void> {
		return super.delete<void>(`/image/${id}`);
	}

	public deleteDocumentMaterialCertificate(id: string): Observable<void> {
		return super.delete<void>(`/material-certificate/${id}`);
	}

	public deleteDocumentDatasheet(id: string): Observable<void> {
		return super.delete<void>(`/datasheet/${id}`);
	}

	public deleteDocumentFillerMaterialCertificate(id: string): Observable<void> {
		return super.delete<void>(`/filler-material-certificate/${id}`);
	}

	public deleteDocumentWeldingProcedure(id: string): Observable<void> {
		return super.delete<void>(`/welding-procedure/${id}`);
	}

	public deleteDocumentWelderCertificate(id: string): Observable<void> {
		return super.delete<void>(`/welder-certificate/${id}`);
	}
}
