<button
	id="{{ id }}"
	type="button"
	class="btn btn-custom"
	(click)="onClick($event)"
	[disabled]="disabled"
	title="{{ alt }}"
>
	{{ value }}
</button>
