import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse } from 'src/app/shared/data-access/models/server-requests-responses.model';
import { TableColumnMetadata } from 'src/app/shared/ui/core/table/table-column-metadata';
import { TableColumnType } from 'src/app/shared/ui/core/table/table.component';

@Injectable({
	providedIn: 'root'
})
export class DocumentWelderCertificateAboutToExpireTableMetadataService {
	constructor(private translateService: TranslateService) {}

	public getMetadata(): TableColumnMetadata<GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse>[] {
		return [
			{
				header: this.translateService.instant('GENERAL.TITLE.LABEL'),
				linkAltText: 'PAGE.DOC_WELDER.TABLE.LINK.ALT',
				field: (documentWelderCertificate: GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse) =>
					documentWelderCertificate.title,
				sortColumn: 'title',
				renderType: TableColumnType.TEXT,
				isLink: true,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('PAGE.EMPLOYEE.TITLE.SINGULAR'),
				field: (documentWelderCertificate: GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse) =>
					documentWelderCertificate.employee?.initials,
				sortColumn: 'employee.initials',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.EXPIRES.ON.LABEL'),
				field: (documentWelderCertificate: GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse) =>
					documentWelderCertificate.expiredDate,
				sortColumn: 'expiredDate',
				renderType: TableColumnType.DATE,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.DAYS.UNTIL.EXPIRED.LABEL'),
				field: (documentWelderCertificate: GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse) =>
					documentWelderCertificate.daysUntilExpired,
				sortColumn: 'daysUntilExpired',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				defaultSortField: true,
				isVisible: true
			}
		];
	}
}
