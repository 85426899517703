import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomNumberPipe } from './custom-number.pipe';

@NgModule({
	declarations: [CustomNumberPipe],
	imports: [CommonModule],
	providers: [DecimalPipe],
	exports: [CustomNumberPipe]
})
export class PipeModule {}
