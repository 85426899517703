export enum LocalStorageKeys {
	KEY_EXPIRE_TIME = 'key_expire_time',
	AUTHENTICATED = 'authenticated',
	QUOTE_ID = 'quoteId',
	QUOTE_REVISION_ID = 'quoteRevisionId',
	QUOTE_ITEM_ID = 'quoteItemId',
	PROJECT_ITEM_ID = 'projectItemId',
	PROJECT_ID = 'projectId',
	QUOTE_ITEM_REVISION_ID = 'quoteItemRevisionId',
	BOM_TANK_IDS = 'bomTankIds',
	BOM_TANK_REVISION_IDS = 'bomTankRevisionIds'
}
