import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	GetUserInformationByAppUserIdResponse,
	LoginCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class ServerAuthenticationService extends RestService {
	constructor(httpClient: HttpClient, restInterceptor: RestInterceptor) {
		super(httpClient, restInterceptor, '/authentication');
	}

	public login(command: LoginCommand): Observable<void> {
		return super.post<void>('/login', command);
	}

	public signOut(): Observable<null> {
		return super.post<null>('/signOut');
	}

	public getUserInformationByAppUserId(): Observable<GetUserInformationByAppUserIdResponse> {
		return super.get<GetUserInformationByAppUserIdResponse>('/user-information');
	}
}
