import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/data-access/services/authentication/authentication.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavBarComponent implements OnInit {
	@ViewChild('menuButtonAdmin')
	private menuButton1!: ElementRef;

	@ViewChild('menuButtonUser')
	private menuButton2!: ElementRef;

	@Input({ required: true }) public title: string | undefined;
	@Input({ required: true }) public username = '';
	@Input({ required: true }) public data!: object;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public navbarItems: any;
	public isOpenPopup = false;
	public openUser = false;
	public openAdmin = false;

	constructor(public authenticationService: AuthenticationService) {}

	ngOnInit(): void {
		this.navbarItems = this.data;
	}

	public openPopup(): void {
		this.isOpenPopup = !this.isOpenPopup;
	}

	public toggleUser(): void {
		this.openUser = !this.openUser;
	}

	public toggleAdmin(): void {
		this.openAdmin = !this.openAdmin;
	}

	public handleKeyUp(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.openPopup();
		}
	}

	@HostListener('document:click', ['$event'])
	public onClick(event: Event): void {
		if (this.openUser && !this.menuButton2.nativeElement.contains(event.target as Node)) {
			this.openUser = false;
		}
		if (this.openAdmin && !this.menuButton1.nativeElement.contains(event.target as Node)) {
			this.openAdmin = false;
		}
	}
}
