export class ApiError {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors?: any[];
	status: number;
	title: string;
	traceId: string;
	type: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(status: number, title: string, traceId: string, type: string, errors?: any[]) {
		this.errors = errors;
		this.status = status;
		this.title = title;
		this.traceId = traceId;
		this.type = type;
	}
}
