export class FormatDate {
	public static formatDateInternationalToDanish(dateValueStr: string): string {
		const dateValue = new Date(dateValueStr);

		const day = dateValue.getDate().toString().padStart(2, '0');
		const month = (dateValue.getMonth() + 1).toString().padStart(2, '0');
		const year = dateValue.getFullYear();

		return `${day}/${month}/${year}`;
	}

	public static formatDateDanishToInternational(dateValueStr: string): string {
		const parts = dateValueStr.split('/');

		const day = parts[0];
		const month = parts[1];
		const year = parts[2];

		return `${year}-${month}-${day}`;
	}
}
