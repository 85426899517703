enum DocumentRoutes {
	DOCUMENT = 'document',
	DOCUMENT_DETAILS = '/:id',
	MATERIAL_CERTIFICATE_LIST = 'material-certificates',
	TEMPLATE_LIST = 'templates',
	IMAGE_LIST = 'images',
	DATASHEET_LIST = 'datasheets',
	WELDER_CERTIFICATE_LIST = 'welder-certificates',
	FILLER_MATERIAL_CERTIFICATE_LIST = 'filler-material-certificates',
	WELDING_PROCEDURE_LIST = 'welding-proocedures',
	ORDER_CONFIRMATION_LIST = 'order-confirmations',
	DRAWING_LIST = 'drawings',
	DRAWING_ATTACHMENT_LIST = 'drawing-validation-attachments',
	SHIPMENT_IMAGE_LIST = 'shipment-images',
	ALL_DOCUMENTS = 'all'
}

export default DocumentRoutes;
