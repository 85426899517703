import { TranslateService } from '@ngx-translate/core';
import { ApiError } from '../../data-access/api-error-handling/api-error';
import { ToastManager } from '../../ui/services/toast/toast-manager.service';

export class ApiErrors {
	static displayErrorsInToasts(error: ApiError, toastManager: ToastManager, translateService: TranslateService): void {
		toastManager.error(translateService.instant('GENERAL.ERROR.TOAST.TITLE'), error.title);
		if (error.errors) {
			for (const field in error.errors) {
				if (Object.prototype.hasOwnProperty.call(error.errors, field)) {
					error.errors[field].forEach((errorMessage: string) => {
						toastManager.error(translateService.instant('GENERAL.ERROR.TOAST.TITLE'), errorMessage);
					});
				}
			}
		}
	}
}
