import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionItemModule } from 'src/app/shared/ui/core/accordion-item/accordion-item.module';
import { AccordionModule } from 'src/app/shared/ui/core/accordion/accordion.module';
import { LoadSpinnerModule } from 'src/app/shared/ui/core/load-spinner/load-spinner.module';
import { TableModule } from 'src/app/shared/ui/core/table/table.module';
import { ButtonModule } from 'src/app/shared/ui/input/button/button.module';
import { DashboardPageRoutingModule } from './dashboard-routing.module';
import { DashboardPage } from './dashboard.page';

@NgModule({
	declarations: [DashboardPage],
	imports: [
		DashboardPageRoutingModule,
		CommonModule,
		TranslateModule,
		ButtonModule,
		TableModule,
		LoadSpinnerModule,
		AccordionItemModule,
		AccordionModule
	],
	providers: []
})
export class DashboardPageModule {}
