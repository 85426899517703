import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {
	constructor(private decimalPipe: DecimalPipe) {}

	transform(value: number, digitsInfo: string = '1.2-2'): string | null {
		return this.decimalPipe.transform(value, digitsInfo);
	}
}
