import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
	CreateDocumentDatasheetResponse,
	CreateDocumentDrawingResponse,
	CreateDocumentDrawingValidationAttachmentResponse,
	CreateDocumentFillerMaterialCertificateResponse,
	CreateDocumentImageResponse,
	CreateDocumentMaterialCertificateResponse,
	CreateDocumentOrderConfirmationResponse,
	CreateDocumentShipmentImageResponse,
	CreateDocumentTemplateResponse,
	CreateDocumentWelderCertificateResponse,
	CreateDocumentWeldingProcedureResponse,
	GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse,
	GetAllDocumentDatasheetsByComponentGroupIdResponse,
	GetAllDocumentDatasheetsByComponentIdResponse,
	GetAllDocumentDatasheetsByTenantIdResponse,
	GetAllDocumentDrawingValidationAttachmentsByTenantIdResponse,
	GetAllDocumentDrawingsByTenantIdResponse,
	GetAllDocumentFillerMaterialCertificatesByTenantIdResponse,
	GetAllDocumentImagesByComponentGroupIdResponse,
	GetAllDocumentImagesByComponentIdResponse,
	GetAllDocumentImagesByTenantIdResponse,
	GetAllDocumentMaterialCertificatesByTenantIdResponse,
	GetAllDocumentOrderConfirmationsByTenantIdResponse,
	GetAllDocumentShipmentImagesByTenantIdResponse,
	GetAllDocumentTemplatesByTenantIdResponse,
	GetAllDocumentWelderCertificatesByEmployeeIdResponse,
	GetAllDocumentWelderCertificatesByTenantIdResponse,
	GetAllDocumentWeldingProceduresByTenantIdResponse,
	GetAllDocumentsByTenantIdResponse,
	GetDocumentDatasheetByIdResponse,
	GetDocumentDrawingByIdResponse,
	GetDocumentDrawingValidationAttachmentByIdResponse,
	GetDocumentFillerMaterialCertificateByIdResponse,
	GetDocumentImageByIdResponse,
	GetDocumentMaterialCertificateByIdResponse,
	GetDocumentOrderConfirmationByIdResponse,
	GetDocumentShipmentImageByIdResponse,
	GetDocumentTemplateByIdResponse,
	GetDocumentWelderCertificateByIdResponse,
	GetDocumentWeldingProcedureByIdResponse,
	UpdateDocumentDatasheetCommand,
	UpdateDocumentDrawingCommand,
	UpdateDocumentDrawingValidationAttachmentCommand,
	UpdateDocumentFillerMaterialCertificateCommand,
	UpdateDocumentImageCommand,
	UpdateDocumentMaterialCertificateCommand,
	UpdateDocumentOrderConfirmationCommand,
	UpdateDocumentShipmentImageCommand,
	UpdateDocumentTemplateCommand,
	UpdateDocumentWelderCertificateCommand,
	UpdateDocumentWeldingProcedureCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { LocalStorageService } from 'src/app/shared/data-access/services/storage/local-storage/local-storage.service';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class DocumentService extends RestService {
	constructor(
		httpClient: HttpClient,
		restInterceptor: RestInterceptor,
		localStorageService: LocalStorageService,
		router: Router,
		toastManager: ToastManager,
		translateService: TranslateService
	) {
		super(httpClient, restInterceptor, localStorageService, router, toastManager, translateService, '/document');
	}

	public getDocumentFillerMaterialCertificateById(
		id: string
	): Observable<GetDocumentFillerMaterialCertificateByIdResponse> {
		return super.get<GetDocumentFillerMaterialCertificateByIdResponse>(`/filler-material-certificate/${id}`);
	}

	public getDocumentMaterialCertificateById(id: string): Observable<GetDocumentMaterialCertificateByIdResponse> {
		return super.get<GetDocumentMaterialCertificateByIdResponse>(`/material-certificate/${id}`);
	}

	public getDocumentDatasheetById(id: string): Observable<GetDocumentDatasheetByIdResponse> {
		return super.get<GetDocumentDatasheetByIdResponse>(`/datasheet/${id}`);
	}

	public getDocumentWelderCertificateById(id: string): Observable<GetDocumentWelderCertificateByIdResponse> {
		return super.get<GetDocumentWelderCertificateByIdResponse>(`/welder-certificate/${id}`);
	}

	public getDocumentWeldingProcedureById(id: string): Observable<GetDocumentWeldingProcedureByIdResponse> {
		return super.get<GetDocumentWeldingProcedureByIdResponse>(`/welding-procedure/${id}`);
	}

	public getDocumentTemplateById(id: string): Observable<GetDocumentTemplateByIdResponse> {
		return super.get<GetDocumentTemplateByIdResponse>(`/template/${id}`);
	}

	public getDocumentImageById(id: string): Observable<GetDocumentImageByIdResponse> {
		return super.get<GetDocumentImageByIdResponse>(`/image/${id}`);
	}

	public getAllDocumentsByTenantId(): Observable<GetAllDocumentsByTenantIdResponse[]> {
		return super.get<GetAllDocumentsByTenantIdResponse[]>(`/documents/tenant-id`);
	}

	public getDocumentOrderConfirmationById(id: string): Observable<GetDocumentOrderConfirmationByIdResponse> {
		return super.get<GetDocumentOrderConfirmationByIdResponse>(`/order-confirmation/${id}`);
	}

	public getDocumentDrawingById(id: string): Observable<GetDocumentDrawingByIdResponse> {
		return super.get<GetDocumentDrawingByIdResponse>(`/drawing/${id}`);
	}

	public getDocumentDrawingValidationAttachmentById(
		id: string
	): Observable<GetDocumentDrawingValidationAttachmentByIdResponse> {
		return super.get<GetDocumentDrawingValidationAttachmentByIdResponse>(`/drawing-validation-attachment/${id}`);
	}

	public getDocumentShipmentImageById(id: string): Observable<GetDocumentShipmentImageByIdResponse> {
		return super.get<GetDocumentShipmentImageByIdResponse>(`/shipment-image/${id}`);
	}

	public getAllDocumentFillerMaterialCertificatesByTenantId(): Observable<
		GetAllDocumentFillerMaterialCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentFillerMaterialCertificatesByTenantIdResponse[]>(
			`/filler-material-certificates/tenant-id`
		);
	}

	public getAllDocumentMaterialCertificatesByTenantId(): Observable<
		GetAllDocumentMaterialCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentMaterialCertificatesByTenantIdResponse[]>(`/material-certificates/tenant-id`);
	}

	public getAllDocumentDatasheetsByTenantId(): Observable<GetAllDocumentDatasheetsByTenantIdResponse[]> {
		return super.get<GetAllDocumentDatasheetsByTenantIdResponse[]>(`/datasheets/tenant-id`);
	}

	public getAllDocumentWelderCertificatesByTenantId(): Observable<
		GetAllDocumentWelderCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentWelderCertificatesByTenantIdResponse[]>(`/welder-certificates/tenant-id`);
	}

	public getAllDocumentWeldingProceduresByTenantId(): Observable<GetAllDocumentWeldingProceduresByTenantIdResponse[]> {
		return super.get<GetAllDocumentWeldingProceduresByTenantIdResponse[]>(`/welding-procedures/tenant-id`);
	}

	public getAllDocumentTemplatesByTenantId(): Observable<GetAllDocumentTemplatesByTenantIdResponse[]> {
		return super.get<GetAllDocumentTemplatesByTenantIdResponse[]>(`/templates/tenant-id`);
	}

	public getAllDocumentImagesByTenantId(): Observable<GetAllDocumentImagesByTenantIdResponse[]> {
		return super.get<GetAllDocumentImagesByTenantIdResponse[]>(`/images/tenant-id`);
	}

	public getAllDocumentDrawingsByTenantId(): Observable<GetAllDocumentDrawingsByTenantIdResponse[]> {
		return super.get<GetAllDocumentDrawingsByTenantIdResponse[]>(`/drawings/tenant-id`);
	}

	public getAllDocumentOrderConfirmationsByTenantId(): Observable<
		GetAllDocumentOrderConfirmationsByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentOrderConfirmationsByTenantIdResponse[]>(`/order-confirmations/tenant-id`);
	}

	public getAllDocumentDrawingValidationAttachmentsByTenantId(): Observable<
		GetAllDocumentDrawingValidationAttachmentsByTenantIdResponse[]
	> {
		return super.get<GetAllDocumentDrawingValidationAttachmentsByTenantIdResponse[]>(
			`/drawing-validation-attachments/tenant-id`
		);
	}

	public getAllDocumentShipmentImagesByTenantId(): Observable<GetAllDocumentShipmentImagesByTenantIdResponse[]> {
		return super.get<GetAllDocumentShipmentImagesByTenantIdResponse[]>(`/shipment-images/tenant-id`);
	}

	public getAllDocumentWelderCertificatesByEmployeeId(
		employeeId: string
	): Observable<GetAllDocumentWelderCertificatesByEmployeeIdResponse[]> {
		return super.get<GetAllDocumentWelderCertificatesByEmployeeIdResponse[]>(
			`/welder-certificates/employee-id/${employeeId}`
		);
	}

	public getAllDocumentDatasheetsByComponentId(
		componentId: string
	): Observable<GetAllDocumentDatasheetsByComponentIdResponse[]> {
		return super.get<GetAllDocumentDatasheetsByComponentIdResponse[]>(`/datasheets/component-id/${componentId}`);
	}

	public getAllDocumentImagesByComponentId(
		componentId: string
	): Observable<GetAllDocumentImagesByComponentIdResponse[]> {
		return super.get<GetAllDocumentImagesByComponentIdResponse[]>(`/images/component-id/${componentId}`);
	}

	public getAllDocumentDatasheetsByComponentGroupId(
		componentGroupId: string
	): Observable<GetAllDocumentDatasheetsByComponentGroupIdResponse[]> {
		return super.get<GetAllDocumentDatasheetsByComponentGroupIdResponse[]>(
			`/datasheets/component-group-id/${componentGroupId}`
		);
	}

	public getAllDocumentImagesByComponentGroupId(
		componentGroupId: string
	): Observable<GetAllDocumentImagesByComponentGroupIdResponse[]> {
		return super.get<GetAllDocumentImagesByComponentGroupIdResponse[]>(
			`/images/component-group-id/${componentGroupId}`
		);
	}

	public getAllAboutToExpireDocumentWelderCertificatesByTenantId(): Observable<
		GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse[]
	> {
		return super.get<GetAllAboutToExpireDocumentWelderCertificatesByTenantIdResponse[]>(
			`/welder-certificates/about-to-expire/tenant-id`
		);
	}

	public downloadDocument(documentId: string): Observable<Blob> {
		return super.get<Blob>(`/download/${documentId}`, 'blob');
	}

	public downloadDocumentDrawing(documentId: string): Observable<Blob> {
		return super.get<Blob>(`/download/drawing/${documentId}`, 'blob');
	}

	public downloadDocumentDrawingValidationAttachment(documentId: string): Observable<Blob> {
		return super.get<Blob>(`/download/drawing-validation-attachment/${documentId}`, 'blob');
	}

	public createDocumentMaterialCertificate(command: FormData): Observable<CreateDocumentMaterialCertificateResponse> {
		return super.post<CreateDocumentMaterialCertificateResponse>('/material-certificate', command);
	}

	public createDocumentTemplate(command: FormData): Observable<CreateDocumentTemplateResponse> {
		return super.post<CreateDocumentTemplateResponse>('/template', command);
	}

	public createDocumentImage(command: FormData): Observable<CreateDocumentImageResponse> {
		return super.post<CreateDocumentImageResponse>('/image', command);
	}

	public createDocumentDatasheet(command: FormData): Observable<CreateDocumentDatasheetResponse> {
		return super.post<CreateDocumentDatasheetResponse>('/datasheet', command);
	}

	public createDocumentFillerMaterialCertificate(
		command: FormData
	): Observable<CreateDocumentFillerMaterialCertificateResponse> {
		return super.post<CreateDocumentFillerMaterialCertificateResponse>('/filler-material-certificate', command);
	}

	public createDocumentWelderCertificate(command: FormData): Observable<CreateDocumentWelderCertificateResponse> {
		return super.post<CreateDocumentWelderCertificateResponse>('/welder-certificate', command);
	}

	public createDocumentWeldingProcedure(command: FormData): Observable<CreateDocumentWeldingProcedureResponse> {
		return super.post<CreateDocumentWeldingProcedureResponse>('/welding-procedure', command);
	}

	public createDocumentDrawing(command: FormData): Observable<CreateDocumentDrawingResponse> {
		return super.post<CreateDocumentDrawingResponse>('/drawing', command);
	}

	public createDocumentDrawingValidationAttachment(
		command: FormData
	): Observable<CreateDocumentDrawingValidationAttachmentResponse> {
		return super.post<CreateDocumentDrawingValidationAttachmentResponse>('/drawing-validation-attachment', command);
	}

	public createDocumentShipmentImage(command: FormData): Observable<CreateDocumentShipmentImageResponse> {
		return super.post<CreateDocumentShipmentImageResponse>('/shipment-image', command);
	}

	public createDocumentOrderConfirmation(command: FormData): Observable<CreateDocumentOrderConfirmationResponse> {
		return super.post<CreateDocumentOrderConfirmationResponse>('/order-confirmation', command);
	}

	public updateDocumentData(command: FormData): Observable<void> {
		return super.patch<void>('', command);
	}

	public updateDocumentDataAsDraftsman(command: FormData): Observable<void> {
		return super.patch<void>('/data/draftsman', command);
	}

	public updateDocumentMaterialCertificate(command: UpdateDocumentMaterialCertificateCommand): Observable<void> {
		return super.patch<void>('/material-certificate', command);
	}

	public updateDocumentTemplate(command: UpdateDocumentTemplateCommand): Observable<void> {
		return super.patch<void>('/template', command);
	}

	public updateDocumentImage(command: UpdateDocumentImageCommand): Observable<void> {
		return super.patch<void>('/image', command);
	}

	public updateDocumentDatasheet(command: UpdateDocumentDatasheetCommand): Observable<void> {
		return super.patch<void>('/datasheet', command);
	}

	public updateDocumentFillerMaterialCertificate(
		command: UpdateDocumentFillerMaterialCertificateCommand
	): Observable<void> {
		return super.patch<void>('/filler-material-certificate', command);
	}

	public updateDocumentWelderCertificate(command: UpdateDocumentWelderCertificateCommand): Observable<void> {
		return super.patch<void>('/welder-certificate', command);
	}

	public updateDocumentWeldingProcedure(command: UpdateDocumentWeldingProcedureCommand): Observable<void> {
		return super.patch<void>('/welding-procedure', command);
	}

	public updateDocumentDrawingValidationAttachment(
		command: UpdateDocumentDrawingValidationAttachmentCommand
	): Observable<void> {
		return super.patch<void>('/drawing-validation-attachment', command);
	}

	public updateDocumentShipmentImage(command: UpdateDocumentShipmentImageCommand): Observable<void> {
		return super.patch<void>('/shipment-image', command);
	}

	public updateDocumentOrderConfirmation(command: UpdateDocumentOrderConfirmationCommand): Observable<void> {
		return super.patch<void>('/order-confirmation', command);
	}

	public updateDocumentDrawing(command: UpdateDocumentDrawingCommand): Observable<void> {
		return super.patch<void>('/drawing', command);
	}

	public deleteDocumentTemplate(id: string): Observable<void> {
		return super.delete<void>(`/template/${id}`);
	}

	public deleteDocumentImage(id: string): Observable<void> {
		return super.delete<void>(`/image/${id}`);
	}

	public deleteDocumentMaterialCertificate(id: string): Observable<void> {
		return super.delete<void>(`/material-certificate/${id}`);
	}

	public deleteDocumentDatasheet(id: string): Observable<void> {
		return super.delete<void>(`/datasheet/${id}`);
	}

	public deleteDocumentFillerMaterialCertificate(id: string): Observable<void> {
		return super.delete<void>(`/filler-material-certificate/${id}`);
	}

	public deleteDocumentWeldingProcedure(id: string): Observable<void> {
		return super.delete<void>(`/welding-procedure/${id}`);
	}

	public deleteDocumentWelderCertificate(id: string): Observable<void> {
		return super.delete<void>(`/welder-certificate/${id}`);
	}

	public deleteDocumentDrawingValidationAttachment(id: string): Observable<void> {
		return super.delete<void>(`/drawing-validation-attachment/${id}`);
	}

	public deleteDocumentShipmentImage(id: string): Observable<void> {
		return super.delete<void>(`/shipment-image/${id}`);
	}

	public deleteDocumentOrderConfirmation(id: string): Observable<void> {
		return super.delete<void>(`/order-confirmation/${id}`);
	}

	public deleteDocumentDrawing(id: string): Observable<void> {
		return super.delete<void>(`/drawing/${id}`);
	}
}
