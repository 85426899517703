import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetAllActiveContactLogsByTenantIdResponse } from 'src/app/shared/data-access/models/server-requests-responses.model';
import { UserInformationService } from 'src/app/shared/data-access/services/user-information/user-information.service';
import { TableColumnMetadata } from 'src/app/shared/ui/core/table/table-column-metadata';
import { TableColumnType } from 'src/app/shared/ui/core/table/table.component';

@Injectable({
	providedIn: 'root'
})
export class ContactLogOthersTableMetadataService {
	constructor(
		private userInformationService: UserInformationService,
		private translateService: TranslateService
	) {}

	public getMetadata(): TableColumnMetadata<GetAllActiveContactLogsByTenantIdResponse>[] {
		return [
			{
				header: this.translateService.instant('GENERAL.DATE_CREATED.LABEL'),
				linkAltText: 'GENERAL.CONTACT.LOG.OPEN.ALT',
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.createdDate,
				sortColumn: 'createdDate',
				renderType: TableColumnType.DATE,
				isLink: true,
				isSortable: true,
				defaultSortField: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.TYPE.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.contactLogType,
				sortColumn: 'contactLogType',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.CREATE_BY.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.createdByAppUser?.employee?.initials,
				sortColumn: 'createdByAppUser.employee.initials',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.RESPONSIBLE_USER.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.appUser?.employee?.initials,
				sortColumn: 'appUser.employee.initials',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.DEADLINE.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.deadline,
				sortColumn: 'deadline',
				renderType: TableColumnType.DATE,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.CONTACT_METHOD.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.contactMethod?.titleDanish,
				sortColumn: 'contactMethod.titleDanish',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesDanishTranslation(),
				exportable: this.userInformationService.usesDanishTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.CONTACT_METHOD.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.contactMethod?.titleEnglish,
				sortColumn: 'contactMethod.titleEnglish',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesEnglishTranslation(),
				exportable: this.userInformationService.usesEnglishTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.CONTACT_METHOD.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.contactMethod?.titleGerman,
				sortColumn: 'contactMethod.titleGerman',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesGermanTranslation(),
				exportable: this.userInformationService.usesGermanTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.STATUS.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.contactLogStatus?.titleDanish,
				sortColumn: 'contactLogStatus.titleDanish',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesDanishTranslation(),
				exportable: this.userInformationService.usesDanishTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.STATUS.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.contactLogStatus?.titleEnglish,
				sortColumn: 'contactLogStatus.titleEnglish',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesEnglishTranslation(),
				exportable: this.userInformationService.usesEnglishTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.STATUS.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.contactLogStatus?.titleGerman,
				sortColumn: 'contactLogStatus.titleGerman',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesGermanTranslation(),
				exportable: this.userInformationService.usesGermanTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.INFORMATION.LABEL'),
				field: (log: GetAllActiveContactLogsByTenantIdResponse) => log.information,
				sortColumn: 'information',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			}
		];
	}
}
