import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
	CreatePurchaseCommand,
	CreatePurchaseResponse,
	GetAllActivePurchasesByTenantIdResponse,
	GetAllPurchaseDetailsByCompanyContactIdAndComponentIdResponse,
	GetAllPurchasesByTenantIdResponse,
	GetPurchaseByIdResponse,
	UpdateAllPurchaseDetailsCommand,
	UpdatePurchaseCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { LocalStorageService } from 'src/app/shared/data-access/services/storage/local-storage/local-storage.service';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class PurchaseService extends RestService {
	constructor(
		httpClient: HttpClient,
		restInterceptor: RestInterceptor,
		localStorageService: LocalStorageService,
		router: Router,
		toastManager: ToastManager,
		translateService: TranslateService
	) {
		super(httpClient, restInterceptor, localStorageService, router, toastManager, translateService, '/purchase');
	}

	public getPurchaseById(id: string): Observable<GetPurchaseByIdResponse> {
		return super.get<GetPurchaseByIdResponse>(`/${id}`);
	}

	public getAllPurchaseDetailsByCompanyContactIdAndComponentId(
		companyContactId: string,
		componentId: string
	): Observable<GetAllPurchaseDetailsByCompanyContactIdAndComponentIdResponse[]> {
		return super.get<GetAllPurchaseDetailsByCompanyContactIdAndComponentIdResponse[]>(
			`/purchase-details/${companyContactId}/${componentId}`
		);
	}

	public getAllPurchasesByTenantId(): Observable<GetAllPurchasesByTenantIdResponse[]> {
		return super.get<GetAllPurchasesByTenantIdResponse[]>(`/purchases/tenant-id`);
	}

	public getAllActivePurchasesByTenantId(): Observable<GetAllActivePurchasesByTenantIdResponse[]> {
		return super.get<GetAllActivePurchasesByTenantIdResponse[]>(`/active-purchases/tenant-id`);
	}

	public downloadPurchaseRequestById(purchaseId: string): Observable<Blob> {
		return super.get<Blob>(`/download-request/${purchaseId}`, 'blob');
	}

	public downloadPurchaseRequisitiontById(purchaseId: string): Observable<Blob> {
		return super.get<Blob>(`/download-requisition/${purchaseId}`, 'blob');
	}

	public createPurchase(command: CreatePurchaseCommand): Observable<CreatePurchaseResponse> {
		return super.post<CreatePurchaseResponse>('', command);
	}

	public updatePurchase(command: UpdatePurchaseCommand): Observable<void> {
		return super.patch<void>('', command);
	}

	public updateAllPurchaseDetails(command: UpdateAllPurchaseDetailsCommand): Observable<void> {
		return super.patch<void>('/purchase-details', command);
	}

	public deletePurchase(id: string): Observable<void> {
		return super.delete<void>(`/${id}`);
	}

	public deletePurchaseDetail(id: string): Observable<void> {
		return super.delete<void>(`/purchase-detail/${id}`);
	}
}
