<div class="accordion-header" (click)="toggle()" (keyup)="this.handleKeyUp($event)" tabindex="0">
	<div>
		<div>
			<span class="accordion-title" [innerHTML]="this.title"></span>
			<i class="accordion-icon" [ngClass]="isOpen ? 'bi bi-chevron-down' : 'bi bi-chevron-right'"></i>
		</div>
	</div>
</div>
<div class="accordion-content" [ngClass]="{ open: isOpen }">
	<ng-content></ng-content>
</div>
