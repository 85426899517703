import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { ApiError } from '../../api-error-handling/api-error';

@Injectable({ providedIn: 'root' })
export class RestInterceptor {
	constructor(
		private toastManager: ToastManager,
		private translateService: TranslateService
	) {}

	public interceptError(error: ApiError) {
		switch (error.status) {
			case 401: {
				this.handleUnauthenticated(error); //TODO: Route to erroe page here.
				break;
			}
			case 402: {
				this.handleUnauthorized(error);
				break;
			}
			default: {
				this.toastManager.error(this.translateService.instant('GENERAL.ERROR.TOAST.TITLE'), error.title);
				break;
			}
		}
	}

	private handleUnauthenticated(error: ApiError) {
		this.toastManager.error(this.translateService.instant('Error'), error.title);
	}

	private handleUnauthorized(error: ApiError) {
		this.toastManager.error(this.translateService.instant('GENERAL.ERROR.TOAST.TITLE'), error.title);
	}
}
