import { Injectable } from '@angular/core';
import { TenantService } from 'src/app/pages/tenant-settings/data-access/services/tenant.service';
import { LocalStorageKeys } from '../../models/local-storage-keys';
import { GetTenantByAppUserIdResponse } from '../../models/server-requests-responses.model';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class TenantInformationService {
	private tenantInformation: GetTenantByAppUserIdResponse | undefined;

	constructor(
		private tenantService: TenantService,
		private localStorageService: LocalStorageService
	) {}

	public initialize(): Promise<boolean> {
		return new Promise((resolve) => {
			const authenticated = this.localStorageService.getItem(LocalStorageKeys.AUTHENTICATED);
			if (!authenticated) {
				resolve(false);
				return;
			}

			this.tenantService
				.getTenantByAppUserId()
				.pipe()
				.subscribe({
					next: (response) => {
						this.tenantInformation = response;
						resolve(true);
					},
					error: () => {
						this.localStorageService.clear();
						resolve(false);
					}
				});
		});
	}

	public removeTenantInformation(): void {
		this.tenantInformation = undefined;
	}

	public getTenantTitle(): string {
		const authenticated = this.isTenantAuthenticated();
		const tenantTitle = this.tenantInformation?.title;

		if (!authenticated || tenantTitle === undefined) {
			return '';
		}

		return tenantTitle;
	}

	public getDefaultCurrency(): string {
		const authenticated = this.isTenantAuthenticated();
		const defaultCurrency = this.tenantInformation?.defaultCurrency;

		if (!authenticated || defaultCurrency === undefined) {
			return '';
		}

		return defaultCurrency;
	}

	public getWorkDayStart(): string {
		const authenticated = this.isTenantAuthenticated();
		const workDayStart = this.tenantInformation?.workDayStart;

		if (!authenticated || workDayStart === undefined) {
			return '';
		}

		return workDayStart;
	}

	public getWorkDayEnd(): string {
		const authenticated = this.isTenantAuthenticated();
		const workDayEnd = this.tenantInformation?.workDayEnd;

		if (!authenticated || workDayEnd === undefined) {
			return '';
		}

		return workDayEnd;
	}

	private isTenantAuthenticated(): boolean {
		const authenticated = this.localStorageService.getItem(LocalStorageKeys.AUTHENTICATED);

		if (authenticated === null || authenticated === undefined || authenticated === false) {
			return false;
		}

		return true;
	}
}
