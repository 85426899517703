<div class="page-container">
	<app-navbar
		*ngIf="this.authenticationService.isLoggedIn()"
		[title]="this.currentTenantTitle"
		[data]="navbarItems"
		[username]="this.username"
	></app-navbar>
	<div>
		<router-outlet></router-outlet>
	</div>
</div>
