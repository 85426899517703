<div class="navbarSection">
	<div class="page-container">
		<div class="navbarcontainer">
			<div class="menuLogosec">
				<div class="navLogo">
					<a routerLink="">{{ this.title }}</a>
				</div>
				<ul class="navbarList">
					<li *ngFor="let item of navbarItems.navbarItems" class="navList">
						<a
							*ngIf="item.type === 'link' && this.authenticationService.isAuthorized(item.requiredPermissions)"
							routerLink="/{{ item.route }}"
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							[title]="item.tooltip | translate"
							class="navLinks"
						>
							{{ item.name | translate }}
						</a>
						<a
							*ngIf="item.type === 'menu' && this.authenticationService.isAuthorized(item.requiredPermissions)"
							href="javascript:void(0)"
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							[title]="item.tooltip | translate"
							class="navLinks"
						>
							{{ item.name | translate }} <img src="assets/images/down-arrow.svg" class="downarrow" alt="" />
						</a>
						<div class="navBarinnerSec" *ngIf="item.type === 'menu'">
							<div class="navinnerListSec">
								<ul class="navInnerList">
									<li *ngFor="let subItem of item.items">
										<a
											*ngIf="this.authenticationService.isAuthorized(subItem.requiredPermissions)"
											routerLink="/{{ subItem.route }}"
											class="navLinksInner"
											data-bs-toggle="tooltip"
											data-bs-placement="bottom"
											[title]="subItem.tooltip | translate"
											>{{ subItem.name | translate }}</a
										>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
				<div class="menuIconSec" (click)="this.openPopup()" (keyup)="this.handleKeyUp($event)" tabindex="0">
					<img src="assets/images/menu.svg" class="menuIcon" id="show-popup-button" alt="" />
				</div>
				<div id="popup-container" [ngClass]="{ 'd-none': !isOpenPopup }">
					<div class="popUpContent" id="popup-content">
						<div class="popupTitle">{{ this.title }}</div>
						<div class="buttonAndItemsRow">
							<button id="close-popup" class="backButton" (click)="this.openPopup()">
								<img src="assets/images/leftarrow.svg" alt="" />
							</button>
							<div class="popUpMenuLists">
								<ng-container *ngFor="let item of navbarItems.navbarItems">
									<ng-container
										*ngIf="
											item.type === 'link' && this.authenticationService.isAuthorized(item.requiredPermissions);
											else subMenuTemplate
										"
									>
										<ul class="popUpList">
											<li>
												<p>
													<a
														routerLink="/{{ item.route }}"
														class="navLinkInner"
														data-bs-toggle="tooltip"
														data-bs-placement="bottom"
														[title]="item.tooltip | translate"
														(click)="this.openPopup()"
														>{{ item.name | translate }}</a
													>
												</p>
											</li>
										</ul>
									</ng-container>
									<ng-template #subMenuTemplate>
										<ul class="popUpList" *ngIf="this.authenticationService.isAuthorized(item.requiredPermissions)">
											<li>
												<p>{{ item.name | translate }} ({{ item?.items?.length }})</p>
											</li>
											<li *ngFor="let sub of item.items">
												<a
													*ngIf="this.authenticationService.isAuthorized(sub.requiredPermissions)"
													routerLink="/{{ sub.route }}"
													class="navLinksInner"
													data-bs-toggle="tooltip"
													data-bs-placement="bottom"
													[title]="sub.tooltip | translate"
													(click)="this.openPopup()"
													>{{ sub.name | translate }}</a
												>
											</li>
										</ul>
									</ng-template>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="settingMenuSec">
				<div
					class="myProfileSec"
					*ngIf="this.authenticationService.isAuthorized(navbarItems.administratorModule.requiredPermissions)"
				>
					<button
						id="menuButton"
						#menuButtonAdmin
						class="settingImgSec"
						(click)="this.toggleAdmin()"
						[title]="navbarItems.administratorModule.tooltip | translate"
					>
						<img src="assets/images/setting.svg" alt="" />
					</button>
					<ul id="menu" class="profileMenu" [ngClass]="{ hidden: !openAdmin }">
						<li *ngFor="let item of navbarItems.administratorModule.items">
							<a
								*ngIf="this.authenticationService.isAuthorized(item.requiredPermissions)"
								routerLink="/{{ item.route }}"
								[title]="item.tooltip | translate"
								>{{ item.name | translate }}</a
							>
						</li>
					</ul>
				</div>
				<div
					class="myProfileSec"
					*ngIf="this.authenticationService.isAuthorized(navbarItems.userToolbar.requiredPermissions)"
				>
					<button
						id="menuButton"
						#menuButtonUser
						class="settingImgSecc"
						(click)="this.toggleUser()"
						data-bs-toggle="tooltip"
						data-bs-placement="bottom"
						[title]="navbarItems.userToolbar.tooltip | translate"
					>
						{{ this.username }}
					</button>
					<ul id="menu" class="profileMenu" [ngClass]="{ hidden: !openUser }">
						<li *ngFor="let item of navbarItems.userToolbar.items">
							<a
								*ngIf="this.authenticationService.isAuthorized(item.requiredPermissions)"
								routerLink="/{{ item.route }}"
								[title]="item.tooltip | translate"
								>{{ item.name | translate }}</a
							>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
