import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-accordion-item',
	templateUrl: './accordion-item.component.html',
	styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent {
	@Input() public isOpen = false;
	@Input({ required: true }) public title: string | undefined;

	public toggle(): void {
		this.isOpen = !this.isOpen;
	}

	public handleKeyUp(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.toggle();
		}
	}
}
