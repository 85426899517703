import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	GetTenantByAppUserIdResponse,
	GetTenantByIdResponse,
	UpdateTenantCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class TenantService extends RestService {
	constructor(httpClient: HttpClient, restInterceptor: RestInterceptor) {
		super(httpClient, restInterceptor, '/tenant');
	}

	public getTenantById(): Observable<GetTenantByIdResponse> {
		return super.get<GetTenantByIdResponse>(``);
	}

	public getTenantByAppUserId(): Observable<GetTenantByAppUserIdResponse> {
		return super.get<GetTenantByAppUserIdResponse>('/app-user');
	}

	public updateTenant(command: UpdateTenantCommand): Observable<void> {
		return super.patch<void>('', command);
	}
}
