import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
	GetTenantByAppUserIdResponse,
	GetTenantByIdResponse,
	UpdateTenantCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { LocalStorageService } from 'src/app/shared/data-access/services/storage/local-storage/local-storage.service';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class TenantService extends RestService {
	constructor(
		httpClient: HttpClient,
		restInterceptor: RestInterceptor,
		localStorageService: LocalStorageService,
		router: Router,
		toastManager: ToastManager,
		translateService: TranslateService
	) {
		super(httpClient, restInterceptor, localStorageService, router, toastManager, translateService, '/tenant');
	}

	public getTenantById(): Observable<GetTenantByIdResponse> {
		return super.get<GetTenantByIdResponse>(``);
	}

	public getTenantByAppUserId(): Observable<GetTenantByAppUserIdResponse> {
		return super.get<GetTenantByAppUserIdResponse>('/app-user');
	}

	public updateTenant(command: UpdateTenantCommand): Observable<void> {
		return super.patch<void>('', command);
	}
}
