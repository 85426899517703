import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
	CreateDrawingValidationCommand,
	CreateDrawingValidationLogCommand,
	CreateDrawingValidationLogResponse,
	CreateDrawingValidationResponse,
	CreateDrawingValidationRevisionCommand,
	CreateDrawingValidationRevisionResponse,
	GetAllActiveDrawingValidationsByTenantIdResponse,
	GetAllDrawingValidationsByTenantIdResponse,
	GetDrawingValidationByIdResponse,
	GetDrawingValidationRevisionByRevisionNumberResponse,
	UpdateClosedDrawingValidationCommand,
	UpdateDrawingValidationCommand,
	UpdateDrawingValidationLogAsDesignerCommand,
	UpdateDrawingValidationLogAsDraftsmanCommand,
	UpdateDrawingValidationLogAsDraftsmanResponse
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { LocalStorageService } from 'src/app/shared/data-access/services/storage/local-storage/local-storage.service';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class DrawingValidationService extends RestService {
	constructor(
		httpClient: HttpClient,
		restInterceptor: RestInterceptor,
		localStorageService: LocalStorageService,
		router: Router,
		toastManager: ToastManager,
		translateService: TranslateService
	) {
		super(
			httpClient,
			restInterceptor,
			localStorageService,
			router,
			toastManager,
			translateService,
			'/drawingValidation'
		);
	}

	public getDrawingValidationById(id: string): Observable<GetDrawingValidationByIdResponse> {
		return super.get<GetDrawingValidationByIdResponse>(`/${id}`);
	}

	public getDrawingValidationRevisionByRevisionNumber(
		drawingValidationId: string,
		revisionNumber: string
	): Observable<GetDrawingValidationRevisionByRevisionNumberResponse> {
		return super.get<GetDrawingValidationRevisionByRevisionNumberResponse>(
			`/${drawingValidationId}/revision/${revisionNumber}`
		);
	}

	public getAllDrawingValidationsByTenantId(): Observable<GetAllDrawingValidationsByTenantIdResponse[]> {
		return super.get<GetAllDrawingValidationsByTenantIdResponse[]>(`/drawing-validations/tenant-id`);
	}

	public getAllActiveDrawingValidationsByTenantId(): Observable<GetAllActiveDrawingValidationsByTenantIdResponse[]> {
		return super.get<GetAllActiveDrawingValidationsByTenantIdResponse[]>(`/active-drawing-validations/tenant-id`);
	}

	public createDrawingValidation(command: CreateDrawingValidationCommand): Observable<CreateDrawingValidationResponse> {
		return super.post<CreateDrawingValidationResponse>('', command);
	}

	public createDrawingValidationRevision(
		command: CreateDrawingValidationRevisionCommand
	): Observable<CreateDrawingValidationRevisionResponse> {
		return super.post<CreateDrawingValidationRevisionResponse>('/revision', command);
	}

	public createDrawingValidationLog(
		command: CreateDrawingValidationLogCommand
	): Observable<CreateDrawingValidationLogResponse> {
		return super.post<CreateDrawingValidationLogResponse>('/drawing-validation-log', command);
	}

	public updateDrawingValidation(command: UpdateDrawingValidationCommand): Observable<void> {
		return super.patch<void>('', command);
	}

	public updateClosedDrawingValidation(command: UpdateClosedDrawingValidationCommand): Observable<void> {
		return super.patch<void>('/closed-drawing-validation', command);
	}

	public updateDrawingValidationLogAsDraftsman(
		command: UpdateDrawingValidationLogAsDraftsmanCommand
	): Observable<UpdateDrawingValidationLogAsDraftsmanResponse> {
		return super.patch<UpdateDrawingValidationLogAsDraftsmanResponse>('/drawing-validation-log/draftsman', command);
	}

	public updateDrawingValidationLogAsDesigner(command: UpdateDrawingValidationLogAsDesignerCommand): Observable<void> {
		return super.patch<void>('/drawing-validation-log/designer', command);
	}

	public deleteDrawingValidation(id: string): Observable<void> {
		return super.delete<void>(`/${id}`);
	}

	public deleteDrawingValidationLog(id: string): Observable<void> {
		return super.delete<void>(`/drawing-validation-log/${id}`);
	}
}
