import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	GetAllActiveContactLogsByAppUserIdResponse,
	GetAllActiveContactLogsByTenantIdResponse
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class DashboardService extends RestService {
	constructor(httpClient: HttpClient, restInterceptor: RestInterceptor) {
		super(httpClient, restInterceptor, '/dashboard');
	}

	public GetAllActiveContactLogsByTenantId(): Observable<GetAllActiveContactLogsByTenantIdResponse[]> {
		return super.get<GetAllActiveContactLogsByTenantIdResponse[]>(`/contact-logs/tenant-id`);
	}

	public GetAllActiveContactLogsByAppUserId(): Observable<GetAllActiveContactLogsByAppUserIdResponse[]> {
		return super.get<GetAllActiveContactLogsByAppUserIdResponse[]>(`/contact-logs/app-user-id`);
	}
}
