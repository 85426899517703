<app-load-spinner [fullScreen]="true"></app-load-spinner>

<app-accordion>
	<div class="flex-container">
		<app-accordion-item
			*ngIf="this.authenticationService.isAuthorized(this.quoteReadPermissions)"
			class="table-container"
			[title]="'PAGE.DASHBOARD.ACTIVE.QUOTES.TITLE' | translate : {total: this.activeQuotes.length.toString(), own: getOwnActiveQuotes()}"
		>
			<app-table
				[tableColumns]="this.quoteAllActiveTableMetadata"
				[data]="this.activeQuotes"
				[showKebabMenu]="false"
				[maxVisibleRows]="this.maxVisibleRows"
				[rowsPerPageOptions]="this.rowsPerPageOptions"
				[exportPermissions]="this.dashboardExportPermissions"
				[defaultSortOrder]="-1"
				(fieldValueLinkEvent)="this.navigateToQuoteDetails($event)"
			></app-table>
		</app-accordion-item>

		<app-accordion-item
			*ngIf="this.authenticationService.isAuthorized(this.quoteReadPermissions)"
			class="table-container"
			[title]="'PAGE.DASHBOARD.AWAITING.CUSTOMER.QUOTES.TITLE' | translate : {total: this.awaitingCustomerQuotes.length.toString(), own: getOwnAwaitingCustomerQuotes()}"
		>
			<app-table
				[tableColumns]="this.quoteAllAwaitingCustomerTableMetadata"
				[data]="this.awaitingCustomerQuotes"
				[showKebabMenu]="false"
				[maxVisibleRows]="this.maxVisibleRows"
				[rowsPerPageOptions]="this.rowsPerPageOptions"
				[exportPermissions]="this.dashboardExportPermissions"
				[defaultSortOrder]="-1"
				(fieldValueLinkEvent)="this.navigateToQuoteDetails($event)"
			></app-table>
		</app-accordion-item>

		<app-accordion-item
			*ngIf="this.authenticationService.isAuthorized(this.dashboardContactLogsReadPermissions)"
			class="table-container"
			[title]="'PAGE.DASHBOARD.CONTACT.LOG.PERSONAL.TITLE' | translate : {total: this.personalActiveContactLogs.length.toString()}"
		>
			<app-table
				[tableColumns]="this.contactLogPersonalTableMetadata"
				[data]="this.personalActiveContactLogs"
				[showKebabMenu]="false"
				[maxVisibleRows]="this.maxVisibleRows"
				[rowsPerPageOptions]="this.rowsPerPageOptions"
				[exportPermissions]="this.dashboardExportPermissions"
				(fieldValueLinkEvent)="this.navigateToContactLogPage($event)"
			></app-table>
		</app-accordion-item>

		<app-accordion-item
			*ngIf="this.authenticationService.isAuthorized(this.dashboardContactLogsAllReadPermissions)"
			class="table-container"
			[title]="'PAGE.DASHBOARD.CONTACT.LOG.UNPERSONAL.TITLE' | translate : {total: this.othersActiveContactLogs.length.toString()}"
		>
			<app-table
				[tableColumns]="this.contactLogOthersTableMetadata"
				[data]="this.othersActiveContactLogs"
				[showKebabMenu]="false"
				[maxVisibleRows]="this.maxVisibleRows"
				[rowsPerPageOptions]="this.rowsPerPageOptions"
				[exportPermissions]="this.dashboardExportPermissions"
				(fieldValueLinkEvent)="this.navigateToContactLogPage($event)"
			></app-table>
		</app-accordion-item>

		<app-accordion-item
			*ngIf="this.authenticationService.isAuthorized(this.projectReadPermissions)"
			class="table-container"
			[title]="'PAGE.DASHBOARD.ACTIVE.PROJECTS.TITLE' | translate : {total: this.activeProjects.length.toString()}"
		>
			<app-table
				[tableColumns]="this.projectAllActiveTableMetadata"
				[data]="this.activeProjects"
				[showKebabMenu]="false"
				[maxVisibleRows]="this.maxVisibleRows"
				[rowsPerPageOptions]="this.rowsPerPageOptions"
				[exportPermissions]="this.dashboardExportPermissions"
				[defaultSortOrder]="-1"
				(fieldValueLinkEvent)="this.navigateToProjectDetails($event)"
			></app-table>
		</app-accordion-item>

		<app-accordion-item
			*ngIf="this.authenticationService.isAuthorized(this.dashboardWelderCertificateReadPermissions)"
			class="table-container"
			[title]="'PAGE.DASHBOARD.ABOUT.TO.EXPIRE.WELDER.QUOTES.TITLE' | translate : {total: this.aboutToExpireDocumentWelderCertificates.length.toString()}"
		>
			<app-table
				[tableColumns]="this.documentWelderCertificateAboutToExpireTableMetadata"
				[data]="this.aboutToExpireDocumentWelderCertificates"
				[showKebabMenu]="false"
				[maxVisibleRows]="this.maxVisibleRows"
				[rowsPerPageOptions]="this.rowsPerPageOptions"
				[exportPermissions]="this.dashboardExportPermissions"
				(fieldValueLinkEvent)="this.navigateToWelderCertificate($event)"
			></app-table>
		</app-accordion-item>
	</div>
</app-accordion>
