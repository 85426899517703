import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
	CreateChangeOrderQuoteCommand,
	CreateChangeOrderQuoteResponse,
	CreateMaterialPriceCommand,
	CreateQuoteCommand,
	CreateQuoteContactLogCommand,
	CreateQuoteFromProjectIdCommand,
	CreateQuoteFromProjectIdResponse,
	CreateQuoteFromQuoteIdCommand,
	CreateQuoteFromQuoteIdResponse,
	CreateQuoteFromQuoteRevisionIdCommand,
	CreateQuoteFromQuoteRevisionIdResponse,
	CreateQuoteResponse,
	CreateQuoteRevisionCommand,
	CreateQuoteRevisionResponse,
	GetAllActiveQuotesByTenantIdResponse,
	GetAllAwaitingCustomerQuotesByTenantIdResponse,
	GetAllQuotesByCompanyContactGroupIdResponse,
	GetAllQuotesByCompanyContactIdResponse,
	GetAllQuotesByContactPersonIdResponse,
	GetAllQuotesByTenantIdResponse,
	GetQuoteByIdResponse,
	GetQuoteRevisionByIdResponse,
	GetQuoteRevisionByRevisionNumberResponse,
	UpdateClosedQuoteCommand,
	UpdateMaterialPriceCommand,
	UpdateQuoteCommand,
	UpdateQuoteContactLogCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { LocalStorageService } from 'src/app/shared/data-access/services/storage/local-storage/local-storage.service';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class QuoteService extends RestService {
	constructor(
		httpClient: HttpClient,
		restInterceptor: RestInterceptor,
		localStorageService: LocalStorageService,
		router: Router,
		toastManager: ToastManager,
		translateService: TranslateService
	) {
		super(httpClient, restInterceptor, localStorageService, router, toastManager, translateService, '/quote');
	}

	public getQuoteById(id: string): Observable<GetQuoteByIdResponse> {
		return super.get<GetQuoteByIdResponse>(`/${id}`);
	}

	public getQuoteRevisionById(quoteRevisionId: string): Observable<GetQuoteRevisionByIdResponse> {
		return super.get<GetQuoteRevisionByIdResponse>(`/revision/${quoteRevisionId}`);
	}

	public getQuoteRevisionByRevisionNumber(
		quoteId: string,
		revisionNumber: number
	): Observable<GetQuoteRevisionByRevisionNumberResponse> {
		return super.get<GetQuoteRevisionByRevisionNumberResponse>(`/${quoteId}/revision/${revisionNumber}`);
	}

	public getAllQuotesByTenantId(): Observable<GetAllQuotesByTenantIdResponse[]> {
		return super.get<GetAllQuotesByTenantIdResponse[]>(`/quotes/tenant-id`);
	}

	public getAllActiveQuotesByTenantId(): Observable<GetAllActiveQuotesByTenantIdResponse[]> {
		return super.get<GetAllActiveQuotesByTenantIdResponse[]>(`/quotes/active/tenant-id`);
	}

	public getAllAwaitingCustomerQuotesByTenantId(): Observable<GetAllAwaitingCustomerQuotesByTenantIdResponse[]> {
		return super.get<GetAllAwaitingCustomerQuotesByTenantIdResponse[]>(`/quotes/awaiting-customer/tenant-id`);
	}

	public getAllQuotesByContactPersonId(contactPersonId: string): Observable<GetAllQuotesByContactPersonIdResponse[]> {
		return super.get<GetAllQuotesByContactPersonIdResponse[]>(`/quotes/contact-person-id/${contactPersonId}`);
	}

	public getAllQuotesByCompanyContactId(
		companyContactId: string
	): Observable<GetAllQuotesByCompanyContactIdResponse[]> {
		return super.get<GetAllQuotesByCompanyContactIdResponse[]>(`/quotes/company-contact-id/${companyContactId}`);
	}

	public getAllQuotesByCompanyContactGroupId(
		companyContactGroupId: string
	): Observable<GetAllQuotesByCompanyContactGroupIdResponse[]> {
		return super.get<GetAllQuotesByCompanyContactGroupIdResponse[]>(
			`/quotes/company-contact-group-id/${companyContactGroupId}`
		);
	}

	public downloadQuoteReportById(quoteId: string): Observable<Blob> {
		return super.get<Blob>(`/download/${quoteId}`, 'blob');
	}

	public downloadQuoteReportByQuoteRevisionId(quoteRevisionId: string): Observable<Blob> {
		return super.get<Blob>(`/download/revision/${quoteRevisionId}`, 'blob');
	}

	public createChangeOrderQuote(command: CreateChangeOrderQuoteCommand): Observable<CreateChangeOrderQuoteResponse> {
		return super.post<CreateChangeOrderQuoteResponse>('/change-order', command);
	}

	public createQuote(command: CreateQuoteCommand): Observable<CreateQuoteResponse> {
		return super.post<CreateQuoteResponse>('', command);
	}

	public createQuoteFromQuoteId(command: CreateQuoteFromQuoteIdCommand): Observable<CreateQuoteFromQuoteIdResponse> {
		return super.post<CreateQuoteFromQuoteIdResponse>('/copy/quote-id', command);
	}

	public createQuoteFromProjectId(
		command: CreateQuoteFromProjectIdCommand
	): Observable<CreateQuoteFromProjectIdResponse> {
		return super.post<CreateQuoteFromProjectIdResponse>('/copy/project-id', command);
	}

	public createQuoteFromQuoteRevisionId(
		command: CreateQuoteFromQuoteRevisionIdCommand
	): Observable<CreateQuoteFromQuoteRevisionIdResponse> {
		return super.post<CreateQuoteFromQuoteRevisionIdResponse>('/copy/quote-revision-id', command);
	}

	public createQuoteRevision(command: CreateQuoteRevisionCommand): Observable<CreateQuoteRevisionResponse> {
		return super.post<CreateQuoteRevisionResponse>('/revision', command);
	}

	public createQuoteContactLog(command: CreateQuoteContactLogCommand): Observable<void> {
		return super.post<void>('/contact-log', command);
	}

	public createMaterialPrice(command: CreateMaterialPriceCommand): Observable<void> {
		return super.post<void>('/material-price', command);
	}

	public updateQuoteContactLog(command: UpdateQuoteContactLogCommand): Observable<void> {
		return super.patch<void>('/contact-log', command);
	}

	public updateMaterialPrice(command: UpdateMaterialPriceCommand): Observable<void> {
		return super.patch<void>('/material-price', command);
	}

	public updateQuote(command: UpdateQuoteCommand): Observable<void> {
		return super.patch<void>('', command);
	}

	public updateClosedQuote(command: UpdateClosedQuoteCommand): Observable<void> {
		return super.patch<void>('/closed-quote', command);
	}

	public deleteQuote(id: string): Observable<void> {
		return super.delete<void>(`/${id}`);
	}

	public deleteMaterialPrice(id: string): Observable<void> {
		return super.delete<void>(`/material-price/${id}`);
	}

	public deleteQuoteContactLog(id: string): Observable<void> {
		return super.delete<void>(`/contact-log/${id}`);
	}
}
