import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
	declarations: [],
	imports: [TableModule, TabViewModule, AutoCompleteModule],
	exports: [TableModule, TabViewModule, AutoCompleteModule]
})
export class PrimengModule {}
