import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	CreateProjectCommand,
	CreateProjectContactLogCommand,
	CreateProjectResponse,
	GetAllActiveProjectsByTenantIdResponse,
	GetAllProjectsByCompanyContactGroupIdResponse,
	GetAllProjectsByCompanyContactIdResponse,
	GetAllProjectsByContactPersonIdResponse,
	GetAllProjectsByTenantIdResponse,
	GetProjectByIdResponse,
	UpdateClosedProjectCommand,
	UpdateProjectCommand,
	UpdateProjectContactLogCommand
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class ProjectService extends RestService {
	constructor(httpClient: HttpClient, restInterceptor: RestInterceptor) {
		super(httpClient, restInterceptor, '/project');
	}

	public getProjectById(id: string): Observable<GetProjectByIdResponse> {
		return super.get<GetProjectByIdResponse>(`/${id}`);
	}

	public getAllProjectsByTenantId(): Observable<GetAllProjectsByTenantIdResponse[]> {
		return super.get<GetAllProjectsByTenantIdResponse[]>(`/projects/tenant-id`);
	}

	public getAllActiveProjectsByTenantId(): Observable<GetAllActiveProjectsByTenantIdResponse[]> {
		return super.get<GetAllActiveProjectsByTenantIdResponse[]>(`/projects/active/tenant-id`);
	}

	public getAllProjectsByContactPersonId(
		contactPersonId: string
	): Observable<GetAllProjectsByContactPersonIdResponse[]> {
		return super.get<GetAllProjectsByContactPersonIdResponse[]>(`/projects/contact-person-id/${contactPersonId}`);
	}

	public getAllProjectsByCompanyContactId(
		companyContactId: string
	): Observable<GetAllProjectsByCompanyContactIdResponse[]> {
		return super.get<GetAllProjectsByCompanyContactIdResponse[]>(`/projects/company-contact-id/${companyContactId}`);
	}

	public getAllProjectsByCompanyContactGroupId(
		companyContactGroupId: string
	): Observable<GetAllProjectsByCompanyContactGroupIdResponse[]> {
		return super.get<GetAllProjectsByCompanyContactGroupIdResponse[]>(
			`/projects/company-contact-group-id/${companyContactGroupId}`
		);
	}

	public createProject(command: CreateProjectCommand): Observable<CreateProjectResponse> {
		return super.post<CreateProjectResponse>('', command);
	}

	public createProjectContactLog(command: CreateProjectContactLogCommand): Observable<void> {
		return super.post<void>('/contact-log', command);
	}

	public updateProject(command: UpdateProjectCommand): Observable<void> {
		return super.patch<void>('', command);
	}

	public updateClosedProject(command: UpdateClosedProjectCommand): Observable<void> {
		return super.patch<void>('/closed-project', command);
	}

	public updateProjectContactLog(command: UpdateProjectContactLogCommand): Observable<void> {
		return super.patch<void>('/contact-log', command);
	}

	public deleteProjectContactLog(id: string): Observable<void> {
		return super.delete<void>(`/contact-log/${id}`);
	}
}
