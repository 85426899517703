import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import AuthenticationRoutes from './pages/authentication/data-access/enums/authentication-routes';
import CompanyContactGroupRoutes from './pages/company-contact-group/data-access/enums/company-contact-routes';
import CompanyContactRoutes from './pages/company-contact/data-access/enums/company-contact-routes';
import ComponentGroupRoutes from './pages/component-group/data-access/enums/component-group-routes';
import ComponentRoutes from './pages/component/data-access/enums/component-routes';
import ContactPersonRoutes from './pages/contact-person/data-access/enums/contact-person-routes';
import DashboardRoutes from './pages/dashboard/data-access/enums/dashboard-routes';
import DocumentRoutes from './pages/document/data-access/enums/document-routes';
import EmployeeRoutes from './pages/employee/data-access/enums/employee-routes';
import ErrorRoutes from './pages/error/data-access/enums/error-routes';
import MaterialRoutes from './pages/material/data-access/enums/material-routes';
import ProductDocumentationTypeRoutes from './pages/product-documentation-type/data-access/enums/product-documentation-type-routes';
import ProjectRoutes from './pages/project/data-access/enums/project-routes';
import QuoteRoutes from './pages/quote/data-access/enums/quote-routes';
import TenantSettingsRoutes from './pages/tenant-settings/data-access/enums/tenant-settings-routes';
import UserSettingsRoutes from './pages/user-settings/data-access/enums/user-settings-routes';
import WorkRecordRoutes from './pages/work-record/data-access/enums/work-record-routes';
import { AuthorizationGuard, AuthorizationGuardRoot } from './shared/data-access/guards/authorization-guard';
import { PermissionType } from './shared/data-access/models/server-requests-responses.model';

const routes: Routes = [
	{
		path: DashboardRoutes.DASHBOARD,
		loadChildren: () => import('./pages/dashboard/feature/dashboard.module').then((m) => m.DashboardPageModule)
	},
	{
		path: AuthenticationRoutes.AUTHENTICATION,
		loadChildren: () =>
			import('./pages/authentication/feature/authentication-shell/authentication-shell.module').then(
				(m) => m.AuthenticationShellModule
			)
	},
	{
		path: EmployeeRoutes.EMPLOYEE_LIST,
		loadChildren: () =>
			import('./pages/employee/feature/employee-shell/employee-shell.module').then((m) => m.EmployeeShellModule)
	},
	{
		path: DocumentRoutes.DOCUMENT,
		loadChildren: () =>
			import('./pages/document/feature/document-shell/document-shell.module').then((m) => m.DocumentShellModule)
	},
	{
		path: CompanyContactRoutes.COMPANY_CONTACT_LIST,
		loadChildren: () =>
			import('./pages/company-contact/feature/company-contact-shell/company-contact-shell.module').then(
				(m) => m.CompanyContactShellModule
			)
	},
	{
		path: CompanyContactGroupRoutes.COMPANY_CONTACT_GROUP_LIST,
		loadChildren: () =>
			import(
				'./pages/company-contact-group/feature/company-contact-group-shell/company-contact-group-shell.module'
			).then((m) => m.CompanyContactGroupShellModule)
	},
	{
		path: ContactPersonRoutes.CONTACT_PERSON_LIST,
		loadChildren: () =>
			import('./pages/contact-person/feature/contact-person-shell/contact-person-shell.module').then(
				(m) => m.ContactPersonShellModule
			)
	},
	{
		path: ProjectRoutes.PROJECT_LIST,
		loadChildren: () =>
			import('./pages/project/feature/project-shell/project-shell.module').then((m) => m.ProjectShellModule)
	},
	{
		path: ComponentGroupRoutes.COMPONENT_GROUP_LIST,
		loadChildren: () =>
			import('./pages/component-group/feature/component-group-shell/component-group-shell.module').then(
				(m) => m.ComponentGroupShellModule
			)
	},
	{
		path: ComponentRoutes.COMPONENT_LIST,
		loadChildren: () =>
			import('./pages/component/feature/component-shell/component-shell.module').then((m) => m.ComponentShellModule)
	},
	{
		path: MaterialRoutes.MATERIAL_LIST,
		loadChildren: () =>
			import('./pages/material/feature/material-shell/material-shell.module').then((m) => m.MaterialShellModule)
	},
	{
		path: ProductDocumentationTypeRoutes.PRODUCT_DOCUMENTAION_TYPE_LIST,
		loadChildren: () =>
			import(
				'./pages/product-documentation-type/feature/product-documentation-type-shell/product-documentation-type-shell.module'
			).then((m) => m.ProductDocumentationTypeShellModule)
	},
	{
		path: QuoteRoutes.QUOTE_LIST,
		loadChildren: () => import('./pages/quote/feature/quote-shell/quote-shell.module').then((m) => m.QuoteShellModule)
	},
	{
		path: UserSettingsRoutes.USER_SETTINGS,
		canMatch: [AuthorizationGuard],
		data: { priviliges: [PermissionType.BasicAccessRead] },
		loadChildren: () =>
			import('./pages/user-settings/feature/user-settings.module').then((m) => m.UserSettingsPageModule)
	},
	{
		path: TenantSettingsRoutes.TENANT_SETTINGS,
		canMatch: [AuthorizationGuard],
		data: { priviliges: [PermissionType.TenantRead] },
		loadChildren: () =>
			import('./pages/tenant-settings/feature/tenant-settings.module').then((m) => m.TenantSettingsPageModule)
	},
	{
		path: WorkRecordRoutes.WORK_RECORD_LIST,
		canMatch: [AuthorizationGuard],
		data: { priviliges: [PermissionType.EmployeeRead] },
		loadChildren: () =>
			import('./pages/work-record/feature/work-record-shell/work-record-shell.module').then(
				(m) => m.WorkRecordShellModule
			)
	},
	{
		path: ErrorRoutes.ERROR,
		loadChildren: () => import('./pages/error/feature/error.module').then((m) => m.ErrorPageModule)
	},
	{
		path: DashboardRoutes.ROOT,
		canMatch: [AuthorizationGuardRoot],
		loadChildren: () =>
			import('./pages/authentication/feature/authentication-shell/authentication-shell.module').then(
				(m) => m.AuthenticationShellModule
			)
	},
	{
		path: '**',
		redirectTo: '/error?type=404&description=Page is not found'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
