import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Table } from 'primeng/table';
import {
	CsvSeparator,
	NumberFormatType,
	PermissionType
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { AuthenticationService } from 'src/app/shared/data-access/services/authentication/authentication.service';
import { TenantInformationService } from 'src/app/shared/data-access/services/tenant-information/tenant-information.service';
import { UserInformationService } from 'src/app/shared/data-access/services/user-information/user-information.service';
import { LoadingService } from '../load-spinner/loading.service';
import { TableColumnMetadata } from './table-column-metadata';

export interface TableColumn {
	header: string;
	field: string;
}

export interface ExportColumn {
	title: string;
	dataKey: string;
	header?: string;
	field?: string;
	exportable?: boolean;
}

export const TableColumnType = {
	TEXT: 'text',
	TELEPHONE_NUMBER: 'telephone_number',
	EMAIL: 'email',
	BOOL: 'bool',
	DATE: 'date',
	CURRENCY: 'currency',
	NUMBER: 'number'
} as const;

export const PdfSize = {
	A4: 'a4',
	A1: 'a1'
} as const;

export type TableColumnType = (typeof TableColumnType)[keyof typeof TableColumnType];

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
	@ViewChild('tableref') tableref: Table | undefined;

	@Input({ required: true }) public data: unknown[] = [];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input({ required: true }) public tableColumns: TableColumnMetadata<any>[] = [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() public expandedTableColumns: TableColumnMetadata<any>[] = [];
	@Input() public createNewAlt = '';
	@Input() public title = '';
	@Input() public defaultSortOrder = 1; // -1, 0, 1
	@Input() public defaultSortOrderExpandedTable = 1; // -1, 0, 1
	@Input() public maxVisibleRows = 25;
	@Input() public rowsPerPageOptions = [25, 50, 100];
	@Input() public paginator = true;
	@Input() public showCurrentPageReport = true;
	@Input() public resizableColumns = true;
	@Input() public showKebabMenu = true;
	@Input() public createBomPermissions: PermissionType[] = [];
	@Input() public copyRowPermissions: PermissionType[] = [];
	@Input() public deleteRowPermissions: PermissionType[] = [];
	@Input() public readRowPermissions: PermissionType[] = [];
	@Input() public editRowPermissions: PermissionType[] = [];
	@Input() public createRowPermissions: PermissionType[] = [];
	@Input() public editExpandedRowPermissions: PermissionType[] = [];
	@Input() public expandableRows = false;
	@Input() public dataKey = 'id';
	@Input() public emptyTableMessage = 'GENERAL.EMPTY.TABLE.LABEL';
	@Input() public emptyExpandableTableMessage = 'GENERAL.EMPTY.TABLE.LABEL';
	@Input() public getExpandedRowData: (rowData: unknown) => unknown[];
	@Input() public optionButton1Label = '';
	@Input() public optionButton1Alt = '';
	@Input() public optionButton2Label = '';
	@Input() public optionButton2Alt = '';
	@Input() public optionButton1Permissions: PermissionType[] = [];
	@Input() public optionButton2Permissions: PermissionType[] = [];
	@Input() public exportPermissions: PermissionType[] = [];
	@Input() public disableOption1Btn = false;
	@Input() public disableOption2Btn = false;
	@Input() public hideTableHeader = false;
	@Input() public exportFileName = 'table-download';
	@Input() public pdfSize: 'a4' | 'a3' | 'a2' | 'a1' = 'a4';

	@Output() public createNewElementEvent = new EventEmitter();
	@Output() public optionButton1Clicked = new EventEmitter();
	@Output() public optionButton2Clicked = new EventEmitter();
	@Output() fieldValueLinkEvent = new EventEmitter<string>();
	@Output() editRowEvent = new EventEmitter<string>();
	@Output() readRowEvent = new EventEmitter<string>();
	@Output() deleteRowEvent = new EventEmitter<string>();
	@Output() copyRowEvent = new EventEmitter<string>();
	@Output() createBomEvent = new EventEmitter<string>();
	@Output() editExpandedRowEvent = new EventEmitter<string>();
	@Output() readExpandedRowEvent = new EventEmitter<string>();
	@Output() deleteExpandedRowEvent = new EventEmitter<string>();
	@Output() rowSelected = new EventEmitter<string>();

	public isLoading = false;
	public selectedRow: unknown;
	public globalFilterFields: string[] = [];
	public openKebabId: string | null = null;
	public defaultSortField: string | undefined;
	public defaultSortFieldExpandedTable: string | undefined;
	public currency: string | undefined;

	constructor(
		public authenticationService: AuthenticationService,
		public tenantInformationService: TenantInformationService,
		private userInformationService: UserInformationService,
		private loadingService: LoadingService
	) {
		this.getExpandedRowData = () => {
			return [];
		};
	}

	ngOnInit(): void {
		this.globalFilterFields = this.tableColumns.map((column) => column.sortColumn);
		this.defaultSortField = this.tableColumns.find((column) => column.defaultSortField == true)?.sortColumn;
		this.defaultSortFieldExpandedTable = this.expandedTableColumns.find(
			(column) => column.defaultSortField == true
		)?.sortColumn;
		this.currency = this.tenantInformationService.getDefaultCurrency();
	}

	public getCsvSeparator(): string {
		const csvSeperator = this.userInformationService.getCsvSeparator();
		if (csvSeperator !== undefined && csvSeperator === CsvSeparator.Comma) {
			return ',';
		} else if (csvSeperator !== undefined && csvSeperator === CsvSeparator.SemiColon) {
			return ';';
		}

		return ',';
	}

	public resolveField(data: unknown, fieldValueFunction: (data: unknown) => string): string {
		const fullText = fieldValueFunction(data);

		if (fullText !== null && fullText !== undefined && fullText.length > 100) {
			return fullText.substring(0, 150).trimEnd() + '...';
		}

		return fullText;
	}

	public resolveFieldAsNumber(data: string): number {
		return Number(data);
	}

	public onRowSelect() {
		const id = this.getId(this.selectedRow);
		if (id === null) {
			return;
		}

		this.rowSelected.emit(id);
	}

	public resolveFieldForBool(data: unknown, fieldValueFunction: (data: unknown) => boolean): boolean {
		const booleanValue = fieldValueFunction(data);
		if (booleanValue) {
			return true;
		}

		return false;
	}

	public resolveFieldForAlt(data: unknown, fieldValueFunction: (data: unknown) => string): unknown {
		const resolvedField = fieldValueFunction(data);
		return { value: resolvedField };
	}

	public createNewTableElement(): void {
		this.createNewElementEvent.emit();
	}

	public sortedColumn: string | null = null;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public onSort(event: any): void {
		this.sortedColumn = event.field;
	}

	public fieldLinkClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.fieldValueLinkEvent.emit(id);
	}

	public handleKeyUp(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.fieldLinkClicked(rowData);
		}
	}

	public handleKeyUpKebab(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.kebabClicked(rowData, event);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public kebabClicked(rowData: any, event: Event): void {
		event.stopPropagation();
		const kebabIcon = event.currentTarget as HTMLElement;
		const kebabMenuContainer = kebabIcon.querySelector('.kebab-items-container') as HTMLElement;

		if (this.openKebabId === rowData.id) {
			this.openKebabId = null;
		} else {
			this.openKebabId = rowData.id;

			const rect = kebabIcon.getBoundingClientRect();

			kebabMenuContainer.style.top = `${rect.bottom}px`;
			kebabMenuContainer.style.left = `${rect.right - kebabMenuContainer.offsetWidth}px`;
		}
	}

	@HostListener('document:click', ['$event'])
	public clickout(event: Event) {
		if (!this.isKebabMenu(event.target)) {
			this.openKebabId = null;
		}
	}

	public editIconClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.editRowEvent.emit(id);
	}

	public handleKeyUpEditIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.editIconClicked(rowData);
		}
	}

	public expandedRowEditIconClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.editExpandedRowEvent.emit(id);
	}

	public handleKeyUpExpandedRowEditIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.expandedRowEditIconClicked(rowData);
		}
	}

	public readIconClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.readRowEvent.emit(id);
	}

	public handleKeyUpReadIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.readIconClicked(rowData);
		}
	}

	public expandedRowReadIconClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.readExpandedRowEvent.emit(id);
	}

	public handleKeyUpExpandedRowReadIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.expandedRowReadIconClicked(rowData);
		}
	}

	public getCombinedPermissions(): string[] {
		return [...this.editRowPermissions, ...this.readRowPermissions];
	}

	public deleteIconClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.deleteRowEvent.emit(id);
	}

	public handleKeyUpDeleteIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.deleteIconClicked(rowData);
		}
	}

	public expandedRowDeleteIconClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.deleteExpandedRowEvent.emit(id);
	}

	public handleKeyUpExpandedRowDeleteIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.expandedRowDeleteIconClicked(rowData);
		}
	}

	public copyIconClicked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.copyRowEvent.emit(id);
	}

	public handleKeyUpCopyIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.copyIconClicked(rowData);
		}
	}

	public handleCsvExport(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.tableref?.exportCSV();
		}
	}

	public handlePdfExport(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.exportPdf();
		}
	}

	public exportPdf() {
		this.loadingService.startLoading();

		const exportColumns: ExportColumn[] = this.tableColumns
			.filter((col) => col.exportable !== false)
			.map((col) => ({
				title: col.header,
				dataKey: col.sortColumn
			}));

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const getNestedValue = (data: any, path: string): string | number => {
			return path.split('.').reduce((acc, part) => (acc !== undefined && acc !== null ? acc[part] : null), data);
		};

		const head = [exportColumns.map((col) => col.title)];
		const body = this.data.map((item) =>
			exportColumns.map((col) => {
				const value = getNestedValue(item, col.dataKey);

				if (value === undefined || value === null) {
					return '';
				} else if (
					Number(value) &&
					col.dataKey !== 'projectNumber' &&
					col.dataKey !== 'quote.quoteNumber' &&
					col.dataKey !== 'quoteNumber'
				) {
					console.log('value', col.dataKey);
					const valueNumber = Number(value);
					const valueFormatted = this.formatValue(valueNumber);
					return valueFormatted;
				} else {
					return value.toString();
				}
			})
		);

		const doc = new jsPDF('landscape', 'px', this.pdfSize);

		autoTable(doc, {
			head: head,
			body: body
		});

		doc.save(this.exportFileName + '.pdf');
		this.loadingService.stopLoading();
	}

	private formatValue(value: number): string {
		if (this.userInformationService.getNumberFormat() === NumberFormatType.Danish) {
			return value.toLocaleString('da-DK');
		} else {
			return value.toLocaleString('en-US');
		}
	}

	public createBomIconCliked(rowData: unknown): void {
		const id = this.getId(rowData);
		if (id === null) {
			return;
		}

		this.createBomEvent.emit(id);
	}

	public handleKeyUpCreateBomIcon(event: KeyboardEvent, rowData: unknown): void {
		if (event.key === 'Enter') {
			this.createBomIconCliked(rowData);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private isKebabMenu(element: any): boolean {
		return element.classList && element.classList.contains('kebab-menu');
	}

	private getId(rowData: unknown): string | null {
		if (typeof rowData === 'object' && rowData !== null && 'id' in rowData && typeof rowData.id === 'string') {
			return rowData.id;
		}

		return null;
	}

	public exportToCSV() {
		if (this.userInformationService.getNumberFormat() === NumberFormatType.English) {
			this.tableref?.exportCSV();
			return;
		}

		this.loadingService.startLoading();

		const originalData = JSON.parse(JSON.stringify(this.data));

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.data = this.data.map((row: any) => {
			const formattedRow = { ...row };
			Object.keys(row).forEach((key) => {
				if (typeof row[key] === 'number') {
					formattedRow[key] = row[key].toString().replace('.', ',');
				}
			});

			return formattedRow;
		});

		setTimeout(() => {
			this.tableref?.exportCSV();

			setTimeout(() => {
				this.data = originalData;
				this.loadingService.stopLoading();
			}, 100);
		}, 300);
	}

	public isNumber(val: string | number): boolean {
		return typeof val === 'number';
	}
}
