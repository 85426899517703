import { Injectable } from '@angular/core';
import { UserInformationService } from '../user-information/user-information.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	constructor(private userInformationService: UserInformationService) {}

	public isAuthorized(permissions: string | string[]): boolean {
		permissions = Array.isArray(permissions) ? permissions : [permissions];
		if (!permissions || permissions == undefined || permissions.length === 0) {
			return false;
		}

		const userInformation = this.userInformationService.getPermissions();
		if (!userInformation) {
			return false;
		}

		return permissions.some((permission) => userInformation.includes(permission));
	}

	public isLoggedIn(): boolean {
		return this.userInformationService.getPermissions() !== undefined;
	}
}
