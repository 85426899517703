<div>
	<p-table
		#tableref
		[columns]="this.tableColumns"
		[value]="data"
		[tableStyle]="{ 'min-width': '50rem' }"
		[paginator]="this.paginator"
		[rows]="this.maxVisibleRows"
		[rowsPerPageOptions]="rowsPerPageOptions"
		[showCurrentPageReport]="this.showCurrentPageReport"
		[globalFilterFields]="this.globalFilterFields"
		[loading]="this.isLoading"
		[selectionMode]="selectionMode"
		[(selection)]="selectedRow"
		(onRowSelect)="onRowSelect()"
		(onSort)="this.onSort($event)"
		[resizableColumns]="this.resizableColumns"
		[sortField]="this.defaultSortField"
		[sortOrder]="this.defaultSortOrder"
		[dataKey]="this.dataKey"
		[csvSeparator]="this.getCsvSeparator()"
		[exportFilename]="this.exportFileName"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	>
		>
		<ng-template *ngIf="!this.hideTableHeader" pTemplate="caption">
			<div class="d-flex justify-content-between caption-container">
				<div class="d-flex caption-container">
					<div *ngIf="this.title !== ''" class="table-title-container">
						<span class="table-title" [innerHTML]="this.title"></span>
					</div>
					<div class="table-global-search-container">
						<div class="table-global-search-items">
							<i class="bi bi-search table-search-icon"></i>
							<input
								pInputText
								type="text"
								class="table-search-input"
								(input)="tableref.filterGlobal($any($event.target).value, 'contains')"
								[placeholder]="'COMPONENT.TABLE.SEARCH.PLACEHOLDER' | translate"
							/>
						</div>
					</div>
					<div
						*ngIf="this.authenticationService.isAuthorized(this.createRowPermissions)"
						class="table-create-button-container"
					>
						<app-input-button
							class="table-create-button"
							[value]="'GENERAL.CREATE.NEW' | translate"
							[alt]="this.createNewAlt"
							(clickEvent)="this.createNewTableElement()"
						></app-input-button>
					</div>
					<div
						*ngIf="this.authenticationService.isAuthorized(this.optionButton1Permissions)"
						class="table-create-button-container"
					>
						<app-input-button
							class="table-create-button"
							[value]="this.optionButton1Label"
							[alt]="this.optionButton1Alt"
							[disabled]="this.disableOption1Btn"
							(clickEvent)="this.optionButton1Clicked.emit()"
						></app-input-button>
					</div>

					<div
						*ngIf="this.authenticationService.isAuthorized(this.optionButton2Permissions)"
						class="table-create-button-container"
					>
						<app-input-button
							class="table-create-button"
							[value]="this.optionButton2Label"
							[alt]="this.optionButton2Alt"
							[disabled]="this.disableOption2Btn"
							(clickEvent)="this.optionButton2Clicked.emit()"
						></app-input-button>
					</div>
				</div>
				<div>
					<i
						*ngIf="this.authenticationService.isAuthorized(this.exportPermissions)"
						[title]="'COMPONENT.TABLE.EXPORT.CSV.ALT' | translate"
						class="bi bi-filetype-csv delete-icon"
						role="button"
						tabindex="0"
						(click)="this.exportToCSV()"
						(keyup)="this.handleCsvExport($event)"
					>
					</i>

					<i
						*ngIf="this.authenticationService.isAuthorized(this.exportPermissions)"
						[title]="'COMPONENT.TABLE.EXPORT.PDF.ALT' | translate"
						class="bi bi-filetype-pdf delete-icon"
						role="button"
						tabindex="0"
						(click)="this.exportPdf()"
						(keyup)="this.handlePdfExport($event)"
					>
					</i>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngIf="this.expandableRows" class="kebab-header"></th>
				<ng-container *ngFor="let col of columns">
					<th
						*ngIf="col.isSortable && col.isVisible"
						pResizableColumn
						class="table-header row-grid"
						[pSortableColumn]="col.sortColumn"
						[class.sorted-column]="sortedColumn === col.sortColumn"
					>
						{{ col.header | translate }} <p-sortIcon [field]="col.sortColumn"></p-sortIcon>
					</th>
					<th *ngIf="!col.isSortable && col.isVisible" pResizableColumn class="table-header row-grid">
						{{ col.header | translate }}
					</th>
				</ng-container>
				<th
					*ngIf="this.showKebabMenu && this.authenticationService.isAuthorized(this.getCombinedPermissions())"
					class="kebab-header"
				></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
			<tr [pSelectableRow]="rowData" [class.selected-row]="isRowSelected(rowData)">
				<td *ngIf="this.expandableRows" class="table-row row-grid">
					<button class="no-style-button" [pRowToggler]="rowData">
						<i
							[title]="'COMPONENT.TABLE.EXPAND.BTN.ALT' | translate"
							[ngClass]="expanded ? 'bi bi-chevron-down' : 'bi bi-chevron-right'"
							class="expand-arrow"
						>
						</i>
					</button>
				</td>
				<ng-container *ngFor="let col of columns">
					<td *ngIf="col.isVisible" class="table-row row-grid">
						<ng-container *ngIf="col.renderType === 'text' || col.renderType === 'date'">
							<ng-container *ngIf="col.isLink; else plainText">
								<span
									class="field-link"
									[title]="col.linkAltText | translate: this.resolveFieldForAlt(rowData, col.field)"
									(click)="this.fieldLinkClicked(rowData)"
									(keyup)="this.handleKeyUp($event, rowData)"
									tabindex="0"
								>
									{{ this.resolveField(rowData, col.field) }}
								</span>
							</ng-container>
							<ng-template #plainText>{{ this.resolveField(rowData, col.field) }}</ng-template>
						</ng-container>
						<ng-container *ngIf="col.renderType === 'telephone_number'">
							<ng-container>
								<a class="telephone-email-link" href="tel:{{ this.resolveField(rowData, col.field) }}">
									{{ this.resolveField(rowData, col.field) }}</a
								>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="col.renderType === 'email'">
							<ng-container>
								<a class="telephone-email-link" href="mailto:{{ this.resolveField(rowData, col.field) }}">
									{{ this.resolveField(rowData, col.field) }}</a
								>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="col.renderType === 'currency'">
							<ng-container>
								<span
									>{{
										this.isNumber(this.resolveField(rowData, col.field)) === false
											? ''
											: (this.resolveField(rowData, col.field) | currency: this.currency)
									}}
								</span>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="col.renderType === 'number'">
							<ng-container>
								<span
									>{{
										this.isNumber(this.resolveField(rowData, col.field)) === false
											? ''
											: (this.resolveFieldAsNumber(this.resolveField(rowData, col.field)) | customNumber)
									}}
								</span>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="col.renderType === 'bool'">
							<ng-container *ngIf="this.resolveFieldForBool(rowData, col.field); else falseTemplate">
								<i class="bi bi-check-square"></i>
							</ng-container>
							<ng-template #falseTemplate>
								<i class="bi bi-square"></i>
							</ng-template>
						</ng-container>
					</td>
				</ng-container>
				<td
					*ngIf="this.showKebabMenu && this.authenticationService.isAuthorized(this.getCombinedPermissions())"
					class="table-row row-grid"
				>
					<div class="kebab-menu-container">
						<i
							[title]="'COMPONENT.TABLE.KEBAB.ALT' | translate"
							class="bi bi-three-dots-vertical kebab-menu"
							role="button"
							tabindex="0"
							(click)="this.kebabClicked(rowData, $event)"
							(keyup)="this.handleKeyUpKebab($event, rowData)"
						>
							<div class="kebab-items-container" [hidden]="openKebabId !== getRowDataKey(rowData)">
								<!-- Read Icon -->
								<i
									*ngIf="this.authenticationService.isAuthorized(this.readRowPermissions)"
									[title]="'COMPONENT.TABLE.KEBAB.VIEW.ALT' | translate"
									class="bi bi-eye kebab-item"
									role="button"
									tabindex="0"
									(click)="this.readIconClicked(rowData)"
									(keyup)="this.handleKeyUpReadIcon($event, rowData)"
								>
								</i>

								<!-- Edit Icon -->
								<i
									*ngIf="this.authenticationService.isAuthorized(this.editRowPermissions)"
									[title]="'COMPONENT.TABLE.KEBAB.EDIT.ALT' | translate"
									class="bi bi-pencil kebab-item"
									role="button"
									tabindex="0"
									(click)="this.editIconClicked(rowData)"
									(keyup)="this.handleKeyUpEditIcon($event, rowData)"
								>
								</i>

								<!-- Allocate Component Icon -->
								<i
									*ngIf="this.authenticationService.isAuthorized(this.allocateComponentPermissions)"
									[title]="'COMPONENT.TABLE.KEBAB.ALLOCATE.ALT' | translate"
									class="bi bi-kanban kebab-item"
									role="button"
									tabindex="0"
									(click)="this.allocateComponentIconClicked(rowData)"
									(keyup)="this.handleKeyUpAllocateComponentIcon($event, rowData)"
								>
								</i>

								<!-- Read Purchases Icon -->
								<i
									*ngIf="this.authenticationService.isAuthorized(this.readPurchasesPermissions)"
									[title]="'COMPONENT.TABLE.KEBAB.PURCHASES.ALT' | translate"
									class="bi bi-receipt kebab-item"
									role="button"
									tabindex="0"
									(click)="this.readPurchasesIconClicked(rowData)"
									(keyup)="this.handleKeyUpReadPurchasesIcon($event, rowData)"
								>
								</i>

								<!-- Copy Icon -->
								<i
									*ngIf="this.authenticationService.isAuthorized(this.copyRowPermissions)"
									[title]="'COMPONENT.TABLE.KEBAB.COPY.ALT' | translate"
									class="bi bi-copy kebab-item"
									role="button"
									tabindex="0"
									(click)="this.copyIconClicked(rowData)"
									(keyup)="this.handleKeyUpCopyIcon($event, rowData)"
								>
								</i>

								<!-- Create Bom Icon -->
								<i
									*ngIf="this.authenticationService.isAuthorized(this.createBomPermissions)"
									[title]="'COMPONENT.TABLE.KEBAB.CREATE.BOM.ALT' | translate"
									class="bi bi-receipt kebab-item"
									role="button"
									tabindex="0"
									(click)="this.createBomIconCliked(rowData)"
									(keyup)="this.handleKeyUpCreateBomIcon($event, rowData)"
								>
								</i>

								<!-- Delete Icon -->
								<i
									*ngIf="this.authenticationService.isAuthorized(this.deleteRowPermissions)"
									[title]="'COMPONENT.TABLE.KEBAB.DELETE.ALT' | translate"
									class="bi bi-trash kebab-item"
									role="button"
									tabindex="0"
									(click)="this.deleteIconClicked(rowData)"
									(keyup)="this.handleKeyUpDeleteIcon($event, rowData)"
								>
								</i>
							</div>
						</i>
					</div>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="6">{{ this.emptyTableMessage | translate }}</td>
			</tr>
		</ng-template>
		<ng-template *ngIf="this.expandableRows" pTemplate="rowexpansion" let-rowData>
			<tr>
				<td colspan="30" class="expanded-table">
					<div>
						<p-table
							[value]="getExpandedRowData(rowData)"
							[dataKey]="this.dataKey"
							[columns]="this.expandedTableColumns"
							[(selection)]="selectedRow"
							selectionMode="single"
							[sortField]="this.defaultSortFieldExpandedTable"
							[sortOrder]="this.defaultSortOrderExpandedTable"
							[tableStyle]="{ 'min-width': '50rem' }"
						>
							<ng-template pTemplate="header" let-columns>
								<tr>
									<ng-container *ngFor="let col of columns">
										<th
											*ngIf="col.isSortable && col.isVisible"
											pResizableColumn
											class="table-header row-grid"
											[pSortableColumn]="col.sortColumn"
											[class.sorted-column]="sortedColumn === col.sortColumn"
										>
											{{ col.header | translate }} <p-sortIcon [field]="col.sortColumn"></p-sortIcon>
										</th>
										<th *ngIf="!col.isSortable && col.isVisible" pResizableColumn class="table-header row-grid">
											{{ col.header | translate }}
										</th>
									</ng-container>
									<th
										*ngIf="this.showKebabMenu && this.authenticationService.isAuthorized(this.getCombinedPermissions())"
										class="kebab-header"
									></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-rowData let-columns="columns">
								<tr [pSelectableRow]="rowData" [class.selected-row]="rowData === selectedRow">
									<ng-container *ngFor="let col of columns">
										<td *ngIf="col.isVisible" class="table-row row-grid">
											<ng-container *ngIf="col.renderType === 'text' || col.renderType === 'date'">
												<ng-container *ngIf="col.isLink; else plainText">
													<span
														class="field-link"
														[title]="col.linkAltText | translate: this.resolveFieldForAlt(rowData, col.field)"
														(click)="this.fieldLinkExpandedClicked(rowData)"
														(keyup)="this.handleKeyUpExpanded($event, rowData)"
														tabindex="0"
													>
														{{ this.resolveField(rowData, col.field) }}
													</span>
												</ng-container>
												<ng-template #plainText>{{ this.resolveField(rowData, col.field) }}</ng-template>
											</ng-container>
											<ng-container *ngIf="col.renderType === 'telephone_number'">
												<ng-container>
													<a class="telephone-email-link" href="tel:{{ this.resolveField(rowData, col.field) }}">
														{{ this.resolveField(rowData, col.field) }}</a
													>
												</ng-container>
											</ng-container>
											<ng-container *ngIf="col.renderType === 'email'">
												<ng-container>
													<a class="telephone-email-link" href="mailto:{{ this.resolveField(rowData, col.field) }}">
														{{ this.resolveField(rowData, col.field) }}</a
													>
												</ng-container>
											</ng-container>
											<ng-container *ngIf="col.renderType === 'currency'">
												<ng-container>
													<span
														>{{
															this.isNumber(this.resolveField(rowData, col.field)) === false
																? ''
																: (this.resolveField(rowData, col.field) | currency: this.currency)
														}}
													</span>
												</ng-container>
											</ng-container>
											<ng-container *ngIf="col.renderType === 'number'">
												<ng-container>
													<span
														>{{
															this.isNumber(this.resolveField(rowData, col.field)) === false
																? ''
																: (this.resolveFieldAsNumber(this.resolveField(rowData, col.field)) | customNumber)
														}}
													</span>
												</ng-container>
											</ng-container>
											<ng-container *ngIf="col.renderType === 'bool'">
												<ng-container *ngIf="this.resolveFieldForBool(rowData, col.field); else falseTemplate">
													<i class="bi bi-check-square"></i>
												</ng-container>
												<ng-template #falseTemplate>
													<i class="bi bi-square"></i>
												</ng-template>
											</ng-container>
										</td>
									</ng-container>
									<td
										*ngIf="this.showKebabMenu && this.authenticationService.isAuthorized(this.getCombinedPermissions())"
										class="table-row row-grid"
									>
										<div class="kebab-menu-container">
											<i
												[title]="'COMPONENT.TABLE.KEBAB.ALT' | translate"
												class="bi bi-three-dots-vertical kebab-menu"
												role="button"
												tabindex="0"
												(click)="this.kebabClicked(rowData, $event)"
												(keyup)="this.handleKeyUpKebab($event, rowData)"
											>
												<div class="kebab-items-container" [hidden]="openKebabId !== getRowDataKey(rowData)">
													<!-- Read Icon -->
													<i
														*ngIf="this.authenticationService.isAuthorized(this.readExpandedRowPermissions)"
														[title]="'COMPONENT.TABLE.KEBAB.VIEW.ALT' | translate"
														class="bi bi-eye kebab-item"
														role="button"
														tabindex="0"
														(click)="this.expandedRowReadIconClicked(rowData)"
														(keyup)="this.handleKeyUpExpandedRowReadIcon($event, rowData)"
													>
													</i>

													<!-- Edit Icon -->
													<i
														*ngIf="this.authenticationService.isAuthorized(this.editExpandedRowPermissions)"
														[title]="'COMPONENT.TABLE.KEBAB.EDIT.ALT' | translate"
														class="bi bi-pencil kebab-item"
														role="button"
														tabindex="0"
														(click)="this.expandedRowEditIconClicked(rowData)"
														(keyup)="this.handleKeyUpExpandedRowEditIcon($event, rowData)"
													>
													</i>

													<!-- Delete Icon -->
													<i
														*ngIf="this.authenticationService.isAuthorized(this.deleteExpandedRowPermissions)"
														[title]="'COMPONENT.TABLE.KEBAB.DELETE.ALT' | translate"
														class="bi bi-trash kebab-item"
														role="button"
														tabindex="0"
														(click)="this.expandedRowDeleteIconClicked(rowData)"
														(keyup)="this.handleKeyUpExpandedRowDeleteIcon($event, rowData)"
													>
													</i>
												</div>
											</i>
										</div>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="6">{{ this.emptyExpandableTableMessage | translate }}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>
